import {atom} from "jotai/index";
import {
    SafCredit,
} from "./SafCreditAtom";

export enum SerCreditState {
    LOCKED_TO_MARKET = "Locked To Market",
    HELD = "Held by Client",
    AVAILABLE = "Available",
    RETIRED = "Retired"
}

export interface SerCredit {
    id: number,
    safCreditId: number
    clientId: number | null //relation to client
    quantity: number,
    state: SerCreditState,
    createdAt: Date,
    retiredDate: Date | null,
    location: string,
    price: number |  null
}


export const serCredits: SerCredit[] = [
    {
        id: 1,
        safCreditId: 1,
        clientId: 1,
        quantity: 806,
        state: SerCreditState.HELD,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: null
    },
    {
        id: 2,
        safCreditId: 2,
        clientId: null,
        quantity: 806,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 3,
        safCreditId: 3,
        clientId: null,
        quantity: 806,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 4,
        safCreditId: 4,
        clientId: null,
        quantity: 806,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 5,
        safCreditId: 5,
        clientId: null,
        quantity: 806,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 6,
        safCreditId: 6,
        clientId: null,
        quantity: 806,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 7,
        safCreditId: 7,
        clientId: 1,
        quantity: 403,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: null
    },
    {
        id: 8,
        safCreditId: 8,
        clientId: null,
        quantity: 322,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 9,
        safCreditId: 9,
        clientId: null,
        quantity: 483,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 10,
        safCreditId: 10,
        clientId: null,
        quantity: 322,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 11,
        safCreditId: 11,
        clientId: null,
        quantity: 322,
        state: SerCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 10
    },
    {
        id: 12,
        safCreditId: 12,
        clientId: null,
        quantity: 219,
        state: SerCreditState.AVAILABLE,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 15
    },
    {
        id: 13,
        safCreditId: 13,
        clientId: 1,
        quantity: 219,
        state: SerCreditState.HELD,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 15
    },
    {
        id: 14,
        safCreditId: 14,
        clientId: 1,
        quantity: 219,
        state: SerCreditState.HELD,
        createdAt: new Date('2023-11-23T02:45:35.374Z'),
        retiredDate: null,
        location: "Sydney",
        price: 15
    },
]
// [
//     {
//         id: 1,
//         safCreditId: 7,
//         clientId: 1,
//         quantity: 210,
//         state: SerCreditState.HELD,
//         createdAt: new Date('2023-11-23T02:45:35.374Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 2,
//         safCreditId: 8,
//         clientId: null,
//         quantity: 239,
//         state: SerCreditState.AVAILABLE,
//         createdAt: new Date('2023-11-23T02:45:35.374Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 3,
//         safCreditId: 9,
//         clientId: null,
//         quantity: 140,
//         state: SerCreditState.LOCKED_TO_MARKET,
//         createdAt: new Date('2023-11-23T02:45:35.374Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 4,
//         safCreditId: 10,
//         clientId: null,
//         quantity: 12,
//         state: SerCreditState.LOCKED_TO_MARKET,
//         createdAt: new Date('2023-11-23T02:45:35.374Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 5,
//         safCreditId: 11,
//         clientId: 2,
//         quantity: 69,
//         state: SerCreditState.HELD,
//         createdAt: new Date('2023-11-23T02:45:35.374Z'),
//         retiredDate: null,
//         location: "Singapore"
//     },
//     {
//         id: 6,
//         safCreditId: 12,
//         clientId: 1,
//         quantity: 167,
//         state: SerCreditState.RETIRED,
//         createdAt: new Date('2023-10-31T16:00:00.000Z'),
//         retiredDate: new Date('2023-11-01T16:00:00.000Z'),
//         location: "Singapore"
//     },
//     {
//         id: 7,
//         safCreditId: 13,
//         clientId: 3,
//         quantity: 208,
//         state: SerCreditState.RETIRED,
//         createdAt: new Date('2023-10-31T16:00:00.000Z'),
//         retiredDate: new Date('2023-11-01T16:00:00.000Z'),
//         location: "Singapore"
//     },
//     {
//         id: 8,
//         safCreditId: 14,
//         clientId: 4,
//         quantity: 165,
//         state: SerCreditState.RETIRED,
//         createdAt: new Date('2023-10-31T16:00:00.000Z'),
//         retiredDate: new Date('2023-11-01T16:00:00.000Z'),
//         location: "Singapore"
//     },
//     {
//         id: 9,
//         safCreditId: 15,
//         clientId: 4,
//         quantity: 123,
//         state: SerCreditState.RETIRED,
//         createdAt: new Date('2023-10-31T16:00:00.000Z'),
//         retiredDate: new Date('2023-11-01T16:00:00.000Z'),
//         location: "Singapore"
//     },
//     {
//         id: 10,
//         safCreditId: 16,
//         clientId: 3,
//         quantity: 95,
//         state: SerCreditState.RETIRED,
//         createdAt: new Date('2023-10-31T16:00:00.000Z'),
//         retiredDate: new Date('2023-11-01T16:00:00.000Z'),
//         location: "Singapore"
//     }
// ]
export const serCreditsAtom = atom<SerCredit[]>(serCredits)

export const getSafCredit = (serCredit: SerCredit, safCredits: SafCredit[]): SafCredit => {
    return safCredits.find((safCredit) => safCredit.id === serCredit.safCreditId)!!
}
//
// function convertToEnumString<T extends Record<string, string>>(
//     keyName: string,
//     item: Record<string, any>,
//     enumObj: T,
//     enumName: string
// ): string {
//     return `${keyName}: ${enumName}.${Object.keys(enumObj)
//         .find(enumKey => enumObj[enumKey as keyof T] === item[keyName])}`;
// }
//
// function print(credits: SerCredit[]) {
//     const str = credits.map((credit: any) => {
//         const safCredit = safCredits.find(safCredit => safCredit.id === credit.safCreditId)!!
//         credit.quantity = safCredit.quantity
//
//         let substr = "{"
//
//         substr += Object.keys(credit).map((key) => {
//             if (Object.values(SerCreditState).includes(credit[key])) {
//                 return convertToEnumString(key, credit, SerCreditState, "SerCreditState")
//             }
//
//             if (credit[key] instanceof Date) {
//                 return ` ${key}: new Date('${credit[key].toISOString()}') `
//             }
//             if (typeof credit[key] === "string") {
//                 return ` ${key}: "${credit[key]}" `
//             }
//
//             return ` ${key}: ${credit[key]} `
//         }).join(',\n')
//         substr += "}"
//         return substr
//     }).join(',\n');
//     console.log(`[\n${str}\n]`);
// }
//
// print(serCredits)