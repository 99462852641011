import {SafCredit, safCreditsAtom, SafCreditState} from "../../atom/SafCreditAtom";
import {DataGrid, GridColDef, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {Box, Button, Dialog, DialogActions, DialogContent, Link, Typography} from "@mui/material";
import {utcToLocalFormat} from "../../utility/utcToLocalFormat";
import React, {useEffect, useState} from "react";
import {useAtom} from "jotai/index";
import {useNavigate} from "react-router-dom";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {itemsAtom} from "../../atom/ItemAtom";
import {SerCredit, SerCreditState} from "../../atom/SerCreditAtom";
import {makeStyles} from "@mui/styles";
import {getCarbonEmissions, getEmissionAbatedDetails} from "../../utility/utils";
import {APP_ROUTES} from "../../utility/AppRoutes";

interface SafCreditTableInt {
    filterState?: SafCreditState,
    setTabValue?: React.Dispatch<React.SetStateAction<SafCreditState>>
}

const SafCreditTable = (params: SafCreditTableInt) => {
    const {filterState, setTabValue} = params
    const [safCredits, setSafCredits] = useAtom(safCreditsAtom)
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });
    const [loading, setLoading] = useState<boolean>()
    const navigate = useNavigate()

    const [items] = useAtom(itemsAtom)
    const [products] = useAtom(productsAtom)
    const getProductFromSafCredit = (credit: SafCredit): Product | null => {
        const item = items.find(item => item.id === credit.itemId)
        const product = products.find(product => product.id === item?.productId)
        return product ?? null
    }

    const [unlockDialogActive, setUnlockDialogActive] = useState<boolean>(false)
    const [selectedUnlockCredit, setSelectedUnlockCredit] = useState<SafCredit | null>(null)
    const appendRetiredDateAndCertificateIfRetired = (arr: GridColDef<SafCredit, any, any>[]) => {
        if (filterState === SafCreditState.RETIRED) {
            let copy = [...arr]
            copy = copy.concat([
                {
                    field: 'retiredDate',
                    headerName: "Retired Date",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {
                        if (!params.row.retiredDate) {
                            return <></>
                        }
                        return (<>{utcToLocalFormat(params.row.retiredDate.toDateString())}</>)
                    },
                },
                {
                    field: 'open pdf',
                    headerName: " ",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {

                        return (<Button variant="outlined" onClick={() => {
                            window.open("/SAF_Certificate.pdf")
                        }}>Retired Cert</Button>)
                    },
                },
            ])
            return copy
        }
        return arr
    }

    const appendUnlockIfLockedToMarket = (arr: GridColDef<SafCredit, any, any>[]) => {
        if (filterState === SafCreditState.LOCKED_TO_MARKET) {
            let copy = [...arr]
            copy = copy.concat([
                {
                    field: 'unlock',
                    headerName: " ",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {

                        return (<Button variant={"outlined"} onClick={() => {
                            setSelectedUnlockCredit(params.row)
                            setUnlockDialogActive(true)
                        }}>unlock</Button>)
                    },
                }
            ])
            return copy
        }
        return arr
    }
    const getColumns = (): GridColDef<SafCredit, any, any>[] => {
        let columns: GridColDef<SafCredit, any, any>[] = [
            {
                field: 'id',
                headerName: "ID",
                minWidth: 150,
                flex: 1
            },
            {
                field: 'product name',
                headerName: "Product Name",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    return (<Link
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(`${APP_ROUTES.admin.inventoryProductDetails.path.replace(":productId", String(getProductFromSafCredit(params.row)?.id))}`)
                        }}
                    >{getProductFromSafCredit(params.row)?.name}</Link>)
                }
            },
            {
                field: 'quantity',
                headerName: "Quantity",
                minWidth: 150,
                flex: 1
            },
            {
                field: 'standard',
                headerName: "Standard",
                minWidth: 150,
                flex: 1
            },
            {
                field: "emissionsAbatedCombustion",
                headerName: "Combustion CO2e Abated (tonnes)",
                minWidth: 150,
                flex: 1,
                filterable: false,
                renderCell: (params) => {
                    const safCredit = params.row
                    const item = items.find(item => item.id === safCredit.itemId)
                    const product = products.find(product => product.id === item?.productId);
                    if (!product || !item) {
                        return <></>
                    }
                    return <>{Math.floor(getEmissionAbatedDetails(product, item.quantity, safCredit.standard).emissionsAbatedCombustion)}</>
                }
            },
            {
                field: "emissionsAbatedLifecycle",
                headerName: "Lifecycle CO2e Abated (tonnes)",
                minWidth: 150,
                flex: 1,
                filterable: false,
                renderCell: (params) => {
                    const safCredit = params.row
                    const item = items.find(item => item.id === safCredit.itemId)
                    const product = products.find(product => product.id === item?.productId);
                    if (!product || !item) {
                        return <></>
                    }
                    return <>{Math.floor(getEmissionAbatedDetails(product, item.quantity, safCredit.standard).emissionsAbatedLifecycle)}</>
                }
            },
            {
                field: "emissionReductionLifecycle",
                headerName: "Lifecycle CO2e reduction (%)",
                minWidth: 150,
                flex: 1,
                filterable: false,
                renderCell: (params) => {
                    const safCredit = params.row
                    const item = items.find(item => item.id === safCredit.itemId)
                    const product = products.find(product => product.id === item?.productId);
                    if (!product || !item) {
                        return <></>
                    }
                    return <>{getEmissionAbatedDetails(product, item.quantity, safCredit.standard).emissionsReductionLifecycle.toFixed(2)}</>
                }
            },
            {
                field: 'createdAt',
                headerName: "Created At",
                minWidth: 200,
                flex: 1,
                renderCell: (params) => {
                    const date = params.value as Date
                    return (<>{utcToLocalFormat(date.toString())}</>)
                }
            },
        ]

        return appendUnlockIfLockedToMarket(appendRetiredDateAndCertificateIfRetired(columns))
    }

    interface CertificateParams {
        ci: string,
        v: string,
        ler: string,
        lea: string,
        cea: string,
        dd: string
    }

    const handleCertificateClick = (id: number) => {
        const safCredit = safCredits.find((credit) => credit.id === id)
        const item = items.find(item => item.id === safCredit?.itemId)
        const product = products.find(product => product.id === item?.productId)
        if (!safCredit || !item || !product) {
            return
        }
        const emissionsAbatedDetails = getEmissionAbatedDetails(product, item.quantity, safCredit.standard)
        const certificateParams = {
            ci: product.carbonIntensityCombustion.toFixed(2),
            v: item.quantity.toString(),
            ler: emissionsAbatedDetails.emissionsReductionLifecycle.toFixed(2),
            lea: emissionsAbatedDetails.emissionsAbatedLifecycle.toFixed(0),
            cea: emissionsAbatedDetails.emissionsAbatedCombustion.toFixed(0),
            dd: item.deliveryDate
        }
        const queryParams = new URLSearchParams(certificateParams);
        window.open(`/document.pdf?${queryParams.toString()}`)
    }

    return (<Box style={{width: '100%', height: 'auto', overflow: 'auto', marginTop: "10px"}}>
        <DataGrid
            autoHeight={true}
            getRowHeight={() => 'auto'}
            sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '22px'
                }
            }}
            slots={{toolbar: GridToolbar}}
            rows={safCredits.filter((safCredit) => safCredit.state === filterState)}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            filterModel={filterModel}
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            loading={loading}
            columns={getColumns()}
            density="compact"
            disableDensitySelector
            aria-label="credits table"
        />
        {unlockDialogActive &&
            <Dialog open={unlockDialogActive}
                    onClose={() => setUnlockDialogActive(false)}>

                <DialogContent>
                    <Typography variant='h2'>
                        Unlock?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUnlockDialogActive(false)} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            const updatedSafCredit = [...safCredits]
                            updatedSafCredit[safCredits.findIndex(credit => credit.id === selectedUnlockCredit!!.id)] =
                                {
                                    ...selectedUnlockCredit!!,
                                    state: SafCreditState.AVAILABLE
                                }
                            setSafCredits(updatedSafCredit)
                            if (setTabValue) {
                                setTabValue(SafCreditState.AVAILABLE)
                            }
                            setUnlockDialogActive(false)
                            setSelectedUnlockCredit(null)
                        }}
                        color="primary"
                        variant="outlined"
                    >
                        Unlock
                    </Button>
                </DialogActions>
            </Dialog>
        }
    </Box>)
}

export default SafCreditTable