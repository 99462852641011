import {
    Box,
    Button,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {DataGrid, GridFilterModel, GridToolbar} from '@mui/x-data-grid';
import {Product, productsAtom, ProductType, selectedProductTypeAtom} from "../../atom/ProductAtom";
import {useAtom} from "jotai";
import ProductSelector from "./ProductSelector";
import React, {useState} from "react";
import GenerateCreditForm, {GenerateCreditFormData, getDefaultGenerateCreditFormData} from "./GenerateCreditForm";
import {currentDateExportFormat} from "../../utility/utcToLocalFormat";
import {sectionSpace} from "../../Themes";
import {useNavigate} from "react-router-dom";
import {Item, itemsAtom} from "../../atom/ItemAtom";
import {getInventoryHolding} from "./utils/InventoryUtils";
import {getCarbonEmissions} from "../../utility/utils";
import AddFuelForm, {AddFuelFormData, getDefaultAddFuelFormData} from "./AddFuelForm";
import {APP_ROUTES} from "../../utility/AppRoutes";

const InventoryManagement = () => {
    const [products, setProducts] = useAtom(productsAtom);
    const [selectedProductType, setSelectedProductType] = useAtom(selectedProductTypeAtom)
    const [generateCreditDialogActive, setGenerateCreditDialogActive] = useState(false);
    const [addFuelDialogActive, setAddFuelDialogActive] = useState(false);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });
    const [generateCreditFormData, setGenerateCreditFormData] = useState<GenerateCreditFormData>(getDefaultGenerateCreditFormData())
    const [addFuelFormData, setAddFuelFormData] = useState<AddFuelFormData>(getDefaultAddFuelFormData())
    const [items] = useAtom(itemsAtom)
    const [loading, setLoading] = useState<boolean>()
    const navigate = useNavigate();

    const filterByProductType = (products: Product[], productType: ProductType | "*"): Product[] => {
        if (productType === "*") {
            return products
        }
        return products.filter((product) => product.feedstockType === productType)
    }

    const openGenerateCreditDialog = () => {
        setGenerateCreditDialogActive(true);
    };

    const closeGenerateCreditDialog = () => {
        setGenerateCreditDialogActive(false);
    };

    const openAddFuelDialog = () => {
        setAddFuelDialogActive(true);
    };

    const closeAddFuelDialog = () => {
        setAddFuelDialogActive(false);
    };

    const generateExportFileName = () => {
        return `${selectedProductType}Products${currentDateExportFormat()}`;
    };


    const getSummaryInventoryHoldings = (products: Product[], productType: ProductType | "*", items: Item[]): number => {
        return filterByProductType(products, productType)
            .reduce((total, product) => total + getInventoryHolding(product.id, items)
                , 0)
    }

    const mapWithInventoryHoldings = (products: Product[], items: Item[]): (Product & {
        inventoryHoldings: number
    })[] => {
        return products.map((product) => ({
            ...product,
            inventoryHoldings: getInventoryHolding(product.id, items),
        }));
    };

    return (
        <>
            <Typography variant="h2">Inventory Management</Typography>
            <ProductSelector/>
            {selectedProductType !== "*" ?
                (<Box sx={{marginBottom: sectionSpace}}>
                    <Typography variant='h3'>
                        Summary
                    </Typography>
                    <TableContainer component={Paper} sx={{width: '520px'}}>
                        <Table size='small' aria-label="product type summary table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{paddingLeft: 0}}
                                               align="right">Inventory Holdings</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell align="right">
                                        {getSummaryInventoryHoldings(products, selectedProductType, items)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>) : null}
            <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">

                <Button
                    disabled={products === undefined}
                    variant="outlined"
                    onClick={openGenerateCreditDialog}
                >
                    Generate Credit
                </Button>
                {generateCreditDialogActive && (
                    <GenerateCreditForm
                        formData={generateCreditFormData}
                        setFormData={setGenerateCreditFormData}
                        generateCreditDialogActive={generateCreditDialogActive}
                        closeGenerateCreditDialog={closeGenerateCreditDialog}
                    />
                )}
                <Button
                    disabled={products === undefined}
                    variant="outlined"
                    onClick={openAddFuelDialog}
                >
                    Add Fuel
                </Button>
                {addFuelDialogActive && (
                    <AddFuelForm
                        addFuelDialogActive={addFuelDialogActive}
                        formData={addFuelFormData}
                        setFormData={setAddFuelFormData}
                        closeAddFuelDialog={closeAddFuelDialog}
                    />
                )}
            </Stack>
            <Box style={{width: '100%', height: 'auto', overflow: 'auto', marginTop: "10px"}}>
                <DataGrid
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '22px'
                        }
                    }}
                    slots={{toolbar: GridToolbar}}
                    rows={mapWithInventoryHoldings(filterByProductType(products, selectedProductType), items)}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    filterModel={filterModel}
                    onFilterModelChange={(newModel) => setFilterModel(newModel)}
                    loading={loading}
                    columns={[
                        {
                            field: 'code',
                            headerName: "Code",
                            minWidth: 150,
                            flex: 1
                        },
                        {
                            field: 'name',
                            headerName: "Name",
                            minWidth: 150,
                            flex: 1
                        },
                        {
                            field: 'feedstockType',
                            headerName: "Feedstock Type",
                            minWidth: 150,
                            flex: 1
                        },

                        {
                            field: 'inventoryHoldings',
                            headerName: "Inventory Holdings (L)",
                            minWidth: 150,
                            flex: 1,
                            filterable: false
                        },
                        {
                            field: "carbonEmissionsCombustion",
                            headerName: "Combustion CO2e (tonnes)",
                            minWidth: 150,
                            flex: 1,
                            filterable: false,
                            renderCell: (params) => {
                                const product = params.row
                                return <>{Math.floor(getCarbonEmissions(product, product.inventoryHoldings).emissionsCombustion)}</>
                            }
                        },
                        {
                            field: "carbonEmissionsLifecycle",
                            headerName: "Lifecycle CO2e (tonnes)",
                            minWidth: 150,
                            flex: 1,
                            filterable: false,
                            renderCell: (params) => {
                                const product = params.row
                                return <>{Math.floor(getCarbonEmissions(product, product.inventoryHoldings).emissionsLifecycle)}</>
                            }
                        }
                    ]}
                    density="compact"
                    disableDensitySelector
                    onRowDoubleClick={(row) => {
                        navigate(
                            `${APP_ROUTES.admin.inventoryProductDetails.path.replace(":productId", row.row.id)}`
                        )
                    }}
                    aria-label="product table"
                />
            </Box>
        </>)
}

export default InventoryManagement;