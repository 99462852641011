import React from "react";
import {Box, Card, CardContent, Typography} from "@mui/material";
import {useAtom} from "jotai";
import {settingsAtom} from "../atom/SettingsAtom";

interface IKpiBox {
    title: string,
    value: string
}

const KpiBox = ({title, value}: IKpiBox) => {
    const [{theme}] = useAtom(settingsAtom)
    console.log(theme.theme.palette.primary.dark)

    return <Card >
        <CardContent>
            <Box>
                <Typography sx={
                    {
                        typography: theme.theme.typography.subtitle1,
                    }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Typography sx={
                    {
                        // color: theme.theme.palette.primary.dark,
                        typography: theme.theme.typography.h4,

                    }}>
                    {value}
                </Typography>
            </Box>
        </CardContent>
    </Card>
}

export default KpiBox