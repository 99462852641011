import {atom} from "jotai/index";

export interface Item {
    id: number,
    productId: number, //relationship to product
    quantity: number,
    consumed: boolean,
    createdAt: Date,
    location: string
    deliveryDate: string
}


export const items: Item[] = [
    {
        id: 1,
        productId: 1,
        quantity: 351332,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 2,
        productId: 1,
        quantity: 351332,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 3,
        productId: 1,
        quantity: 351332,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 4,
        productId: 1,
        quantity: 351332,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 5,
        productId: 1,
        quantity: 351332,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 6,
        productId: 1,
        quantity: 351332,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 7,
        productId: 1,
        quantity: 175782,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 8,
        productId: 1,
        quantity: 140533,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 9,
        productId: 1,
        quantity: 210779,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 10,
        productId: 1,
        quantity: 140533,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 11,
        productId: 1,
        quantity: 140533,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 12,
        productId: 2,
        quantity: 100000,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 13,
        productId: 2,
        quantity: 100000,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 14,
        productId: 2,
        quantity: 100000,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 15,
        productId: 2,
        quantity: 100000,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 16,
        productId: 2,
        quantity: 100000,
        consumed: true,
        createdAt: new Date('2023-11-01'),
        location: "Sydney",
        deliveryDate: "Mar-May 2023"
    },
    {
        id: 17,
        productId: 3,
        quantity: 500000,
        consumed: false,
        createdAt: new Date('2024-05-10T08:00:00Z'),
        location: 'Vantaa, Finland',
        deliveryDate: '2024-06-15'
    },
    {
        id: 18,
        productId: 4,
        quantity: 1500000,
        consumed: false,
        createdAt: new Date('2024-05-15T08:00:00Z'),
        location: 'Tampere, Finland',
        deliveryDate: '2024-06-20'
    },
    {
        id: 19,
        productId: 5,
        quantity: 3500000,
        consumed: true,
        createdAt: new Date('2024-05-20T08:00:00Z'),
        location: 'Oulu, Finland',
        deliveryDate: '2024-06-25'
    },
    {
        id: 20,
        productId: 6,
        quantity: 400000,
        consumed: true,
        createdAt: new Date('2024-05-25T08:00:00Z'),
        location: 'Turku, Finland',
        deliveryDate: '2024-06-30'
    },
    {
        id: 21,
        productId: 7,
        quantity: 3200000,
        consumed: true,
        createdAt: new Date('2024-05-30T08:00:00Z'),
        location: 'Lahti, Finland',
        deliveryDate: '2024-07-05'
    },
    {
        id: 22,
        productId: 8,
        quantity: 280000,
        consumed: true,
        createdAt: new Date('2024-06-01T08:00:00Z'),
        location: 'Jyväskylä, Finland',
        deliveryDate: '2024-07-10'
    },
    {
        id: 23,
        productId: 9,
        quantity: 3100000,
        consumed: true,
        createdAt: new Date('2024-06-05T08:00:00Z'),
        location: 'Kuopio, Finland',
        deliveryDate: '2024-07-15'
    },
    {
        id: 24,
        productId: 10,
        quantity: 3300000,
        consumed: false,
        createdAt: new Date('2024-06-10T08:00:00Z'),
        location: 'Rovaniemi, Finland',
        deliveryDate: '2024-07-20'
    }
]
//     [
//     {
//         id: 1,
//         productId: 1,
//         quantity: 967000,
//         consumed: true,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 2,
//         productId: 1,
//         quantity: 341000,
//         consumed: true,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 3,
//         productId: 1,
//         quantity: 572000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 4,
//         productId: 1,
//         quantity: 408000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 5,
//         productId: 1,
//         quantity: 510000,
//         consumed: true,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 6,
//         productId: 2,
//         quantity: 620000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 7,
//         productId: 2,
//         quantity: 736000,
//         consumed: true,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 8,
//         productId: 2,
//         quantity: 933000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 9,
//         productId: 2,
//         quantity: 512000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 10,
//         productId: 2,
//         quantity: 37000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 11,
//         productId: 3,
//         quantity: 265000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 12,
//         productId: 3,
//         quantity: 573000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 13,
//         productId: 3,
//         quantity: 654000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 14,
//         productId: 3,
//         quantity: 593000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 15,
//         productId: 3,
//         quantity: 485000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 16,
//         productId: 4,
//         quantity: 298000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 17,
//         productId: 4,
//         quantity: 290000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 18,
//         productId: 4,
//         quantity: 538000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 19,
//         productId: 4,
//         quantity: 64000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 20,
//         productId: 4,
//         quantity: 614000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 21,
//         productId: 5,
//         quantity: 11000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 22,
//         productId: 5,
//         quantity: 261000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 23,
//         productId: 5,
//         quantity: 695000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 24,
//         productId: 5,
//         quantity: 807000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 25,
//         productId: 5,
//         quantity: 283000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 26,
//         productId: 6,
//         quantity: 968000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 27,
//         productId: 6,
//         quantity: 893000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 28,
//         productId: 6,
//         quantity: 8000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 29,
//         productId: 6,
//         quantity: 931000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 30,
//         productId: 6,
//         quantity: 287000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 31,
//         productId: 7,
//         quantity: 572000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 32,
//         productId: 7,
//         quantity: 181000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 33,
//         productId: 7,
//         quantity: 791000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 34,
//         productId: 7,
//         quantity: 369000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 35,
//         productId: 7,
//         quantity: 357000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 36,
//         productId: 8,
//         quantity: 10000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 37,
//         productId: 8,
//         quantity: 673000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 38,
//         productId: 8,
//         quantity: 625000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 39,
//         productId: 8,
//         quantity: 975000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 40,
//         productId: 8,
//         quantity: 426000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 41,
//         productId: 9,
//         quantity: 875000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 42,
//         productId: 9,
//         quantity: 988000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 43,
//         productId: 9,
//         quantity: 852000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 44,
//         productId: 9,
//         quantity: 457000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 45,
//         productId: 9,
//         quantity: 275000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 46,
//         productId: 10,
//         quantity: 926000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 47,
//         productId: 10,
//         quantity: 627000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 48,
//         productId: 10,
//         quantity: 410000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 49,
//         productId: 10,
//         quantity: 840000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 50,
//         productId: 10,
//         quantity: 448000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 51,
//         productId: 11,
//         quantity: 326000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 52,
//         productId: 11,
//         quantity: 465000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 53,
//         productId: 11,
//         quantity: 603000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 54,
//         productId: 11,
//         quantity: 486000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 55,
//         productId: 11,
//         quantity: 63000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 56,
//         productId: 12,
//         quantity: 746000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 57,
//         productId: 12,
//         quantity: 428000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 58,
//         productId: 12,
//         quantity: 148000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 59,
//         productId: 12,
//         quantity: 945000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 60,
//         productId: 12,
//         quantity: 310000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 61,
//         productId: 13,
//         quantity: 84000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 62,
//         productId: 13,
//         quantity: 711000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 63,
//         productId: 13,
//         quantity: 995000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 64,
//         productId: 13,
//         quantity: 56000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 65,
//         productId: 13,
//         quantity: 17000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 66,
//         productId: 14,
//         quantity: 40000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 67,
//         productId: 14,
//         quantity: 604000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 68,
//         productId: 14,
//         quantity: 663000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 69,
//         productId: 14,
//         quantity: 147000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 70,
//         productId: 14,
//         quantity: 369000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 71,
//         productId: 15,
//         quantity: 808000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 72,
//         productId: 15,
//         quantity: 274000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 73,
//         productId: 15,
//         quantity: 584000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 74,
//         productId: 15,
//         quantity: 436000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 75,
//         productId: 15,
//         quantity: 335000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 76,
//         productId: 16,
//         quantity: 206000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 77,
//         productId: 16,
//         quantity: 160000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 78,
//         productId: 16,
//         quantity: 154000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 79,
//         productId: 16,
//         quantity: 900000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 80,
//         productId: 16,
//         quantity: 644000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 81,
//         productId: 17,
//         quantity: 6000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 82,
//         productId: 17,
//         quantity: 166000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 83,
//         productId: 17,
//         quantity: 934000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 84,
//         productId: 17,
//         quantity: 630000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 85,
//         productId: 17,
//         quantity: 504000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 86,
//         productId: 18,
//         quantity: 359000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 87,
//         productId: 18,
//         quantity: 741000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 88,
//         productId: 18,
//         quantity: 968000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 89,
//         productId: 18,
//         quantity: 416000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 90,
//         productId: 18,
//         quantity: 857000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 91,
//         productId: 19,
//         quantity: 854000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 92,
//         productId: 19,
//         quantity: 738000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 93,
//         productId: 19,
//         quantity: 358000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 94,
//         productId: 19,
//         quantity: 778000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 95,
//         productId: 19,
//         quantity: 685000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 96,
//         productId: 20,
//         quantity: 636000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 97,
//         productId: 20,
//         quantity: 121000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 98,
//         productId: 20,
//         quantity: 685000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 99,
//         productId: 20,
//         quantity: 719000,
//         consumed: true,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     },
//     {
//         id: 100,
//         productId: 20,
//         quantity: 289000,
//         consumed: false,
//         createdAt: new Date('2023-11-01'),
//         location: "Sydney",
//         deliveryDate: "Mar-May 2023"
//     }
// ]
export const itemsAtom = atom<Item[]>(items)


// function addField(rows: Item[]) {
//
//     return rows.map(row => {
//         Math.random()
//         return {
//             ...row,
//             deliveryDate: "Mar-May 2023"
//         }
//     });
// }
//
// function print(rows: any) {
//     const productsString = rows.map((product: any) => {
//         let str = "{"
//         str += Object.keys(product).map((key) => {
//             if (key === "createdAt") {
//                 return ` ${key}: new Date('2023-11-01') `
//             }
//             return ` ${key}: ${JSON.stringify(product[key])} `
//         }).join(',\n')
//         str += "}"
//         return str
//     }).join(',\n');
//
//     console.log(`[\n${productsString}\n]`);
// }
//
// const updatedProducts = addField(items);
// print(updatedProducts);
