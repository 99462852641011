import {Box, MenuItem, Select, Typography} from "@mui/material";
import {useAtom} from "jotai";
import {productsAtom, ProductType, selectedProductTypeAtom} from "../../atom/ProductAtom";

const ProductSelector = () => {
    const [products, setProducts] = useAtom(productsAtom);
    const [selectedProductType, setSelectedProductType] = useAtom(selectedProductTypeAtom)

    const handleProductChange = (event: any) => {
        setSelectedProductType(event.target.value)
    }

    return (<>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: 1
            }}
        >
            <Typography sx={{marginRight: 2}}>
                Feedstock Type:
            </Typography>
            <Select
                size="small"
                id="product-code"
                value={selectedProductType == null ? "" : selectedProductType}
                onChange={handleProductChange}
                sx={{m: 1, minWidth: 400}}
            >
                <MenuItem value='*'>
                    Any
                </MenuItem>
                {Object.keys(ProductType).map((key) => {
                    const enumKey = key as keyof typeof ProductType
                    return (
                        <MenuItem value={ProductType[enumKey]} key={ProductType[enumKey]}>
                            {ProductType[enumKey]}
                        </MenuItem>
                    )
                })}
            </Select>
        </Box>
    </>)
}

export default ProductSelector