import HomePage from '../view/HomePage';
import InventoryManagement from "../view/inventoryManagement/InventoryManagement";
import ProductDetails from "../view/inventoryManagement/ProductDetails";
import ClientManagement from "../view/clientManagement/ClientManagement";
import SerManagement from "../view/serManagement/SerManagement";
import SafManagement from "../view/safManagement/SafManagement";
import Home from "../view/client/Home";
import Marketplace from "../view/client/Marketplace";


export const homepage = "/app"
export const adminHomepage = `${homepage}/admin`
export const clientHomepage = `${homepage}/client`
export const APP_ROUTES = {
    admin: {
        inventoryManagement: {
            label: 'INVENTORY MANAGEMENT',
            path: `${adminHomepage}/inventory-management`,
            component: <InventoryManagement/>,
        },
        inventoryProductDetails: {
            label: 'PRODUCT DETAILS',
            path: `${adminHomepage}/inventory-management/product/:productId`,
            component: <ProductDetails/>,
        },
        safManagement: {
            label: 'SAFc MANAGEMENT',
            path: `${adminHomepage}/saf-management`,
            component: <SafManagement/>
        },
        serManagement: {
            label: 'SERc MANAGEMENT',
            path: `${adminHomepage}/ser-management`,
            component: <SerManagement/>
        },
        clientManagement: {
            label: 'CLIENT MANAGEMENT',
            path: `${adminHomepage}/client-management`,
            component: <ClientManagement/>
        },
    },
    client: {
        home: {
            label: 'HOME',
            path: `${clientHomepage}/home`,
            component: <Home/>,
        },
        marketplace: {
            label: 'MARKETPLACE',
            path: `${clientHomepage}/marketplace`,
            component: <Marketplace/>,
        }
    }
};
