import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

let backendUrl = ''; // Do not change this. Change the value below when testing.
if (process.env["REACT_APP_DEV_MODE"] === "true") {
    // When testing locally, use the 'integ1' back end
    // This can be changed to any development env or alternatively 'http://localhost:8080' if running a local backend.
    backendUrl = 'https://book-and-claim-integ1.dev.trovio.io';
    // To use this back end, but apply the app config for another environment, see instructions below.
}

// To overwrite the app config used for local front end development, run the command below in the 'book-and-claim-frontend' directory:
// yq -o=json '.FRONTEND' "../config/env-{env}.yaml" > public/dev-app-config-override.json
// Then start npm with: npm run start_custom_app_config
let settingsUrl = `${backendUrl}/frontend-app-config`; // Do not change this
if (process.env["REACT_APP_DEV_MODE"] === "true" && process.env["REACT_APP_CUSTOM_APP_CONFIG"] === "true") {
    settingsUrl = 'http://localhost:3000/dev-app-config-override.json'
}

// These variables are set later when AppConfigProvider loads.
// They are kept as global static variables for ease of access in helper functions including in .js libs imported from core10.
let cortenApiUrl = '';
let cortenApiKey = '';

type AppConfigContextType = {
    settingsLoaded: () => boolean;
    getAuthConfig: () => any;
    getThemes: () => ThemeConfig[];
};

interface ThemeConfig {
    name: string;
    displayName: string;
    default: boolean;
}

const fetchAppConfig = async ()=> {
    return fetch(settingsUrl, {headers: 
        {'Content-Type': 'application/json','Accept': 'application/json'}
    });
}

const AppConfigStateContext = createContext<AppConfigContextType | null>(null);

const AppConfigProvider = ({ children, appConfig }: { children?: ReactNode, appConfig: any }) => {
    const [settings, setSettings] = useState<any>(undefined);

    useEffect(()=> {
        cortenApiUrl = appConfig.CORTEN_URL;
        cortenApiKey = appConfig.CORTEN_API_KEY;
        setSettings(appConfig);
    },[])

    const settingsLoaded = () => {
        return settings !== undefined;
    }

    const getAuthConfig = (): any => {
        return settings['AUTH_CONFIG']
    };

    const getThemes = (): ThemeConfig[] => {
        return Object.entries(settings['THEMES']).map(([name, config]: [string, any]) => ({
            name: name,
            displayName: config['DISPLAY_NAME'],
            default: config['DEFAULT'] === true ?? false,
        }));
    };

    return (
        <AppConfigStateContext.Provider
            value={{
                settingsLoaded,
                getAuthConfig,
                getThemes
            }}
        >
            {children}
        </AppConfigStateContext.Provider>
    );
};

function useAppConfigState() {
    const context = useContext(AppConfigStateContext);
    if (!context) {
        throw new Error('no provider for AppConfigState');
    }
    return context;
}

export {
    backendUrl,
    fetchAppConfig,
    cortenApiUrl,
    cortenApiKey,
    AppConfigProvider,
    useAppConfigState
};
