import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useAtom} from "jotai";
import {SerCredit, serCreditsAtom, SerCreditState} from "../../atom/SerCreditAtom";
import {sectionSpace} from "../../Themes";
import {useLayoutState} from "../../state/Layout";
import {DataGrid, GridColDef, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {mapEnumToZeroValues} from "../../utility/utils";
import {utcToLocalFormat} from "../../utility/utcToLocalFormat";
import {Client, clientsAtom} from "../../atom/ClientAtom";
import {SafCredit, safCreditsAtom, SafCreditState} from "../../atom/SafCreditAtom";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {itemsAtom} from "../../atom/ItemAtom";
import {useNavigate} from "react-router-dom";
import SerCreditTable from "./SerCreditTable";

const SerManagement = () => {
    const [serCredits, setSerCredits] = useAtom(serCreditsAtom)
    const [tabValue, setTabValue] = useState<SerCreditState>(SerCreditState.AVAILABLE);
    const [loading, setLoading] = useState<boolean>()
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });

    const [clients] = useAtom(clientsAtom)
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState<boolean>(false);
    const [transferDialogActive, setTransferDialogActive] = useState<boolean>(false);

    interface LockToMarketFormData {
        serCredit: SerCredit | null,
        quantity: number,
        price: number
    }

    const [lockToMarketFormData, setLockToMarketFormData] = useState<LockToMarketFormData>({
        serCredit: null,
        quantity: 0,
        price: 1
    })

    interface TransferFormData {
        serCredit: SerCredit | null,
        quantity: number
        to: Client | null
    }

    const [transferFormData, setTransferFormData] = useState<TransferFormData>({
        serCredit: null,
        quantity: 0,
        to: null
    })
    const getSummary = (credits: SerCredit[]) => {
        return credits.reduce((summary, credit) => {
            const {state, quantity} = credit;
            summary[state] += quantity
            return summary;
        }, mapEnumToZeroValues(SerCreditState))
    }

    const {customTheme} = useLayoutState();

    const hoverTabTextColor = {color: customTheme.theme.palette.secondary.main};

    const onTabChange = (event: React.SyntheticEvent, value: any): void => {
        setTabValue(value);
    }

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true)
    }

    const closeLockToMarketDialog = () => {
        setLockToMarketDialogActive(false)
    }

    const openTransferDialog = () => {
        setTransferDialogActive(true)
    }

    const closeTransferDialog = () => {
        setTransferDialogActive(false)
    }

    const [safCredits] = useAtom(safCreditsAtom)
    const [items] = useAtom(itemsAtom)
    const [products] = useAtom(productsAtom)

    const getProductFromSerCredit = (credit: SerCredit): Product | null => {
        const safCredit = safCredits.find((safCredit => safCredit.id === credit.safCreditId))
        const item = items.find(item => item.id === safCredit?.itemId)
        const product = products.find(product => product.id === item?.productId)
        return product ?? null
    }


    const getClientFromSerCredit = (credit: SerCredit) : Client | null => {
        if (!credit.clientId) {
            return null
        }
        return clients.find(client => client.id === credit.clientId) ?? null
    }

    const lockToMarket = () => {

        if (lockToMarketFormData.quantity === lockToMarketFormData.serCredit?.quantity) {
            const updatedSerCredit = [...serCredits]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === lockToMarketFormData.serCredit?.id)] =
                {
                    ...lockToMarketFormData.serCredit!!,
                    state: SerCreditState.LOCKED_TO_MARKET,
                }
            setSerCredits(updatedSerCredit)
        } else {
            const updatedSerCredit = [...serCredits,
                {
                    ...lockToMarketFormData.serCredit!!,
                    quantity: lockToMarketFormData.serCredit?.quantity!! - lockToMarketFormData.quantity
                }
            ]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === lockToMarketFormData.serCredit?.id)] =
                {
                    ...lockToMarketFormData.serCredit!!,
                    state: SerCreditState.LOCKED_TO_MARKET,
                    id: safCredits.length + 1,
                    quantity: lockToMarketFormData.quantity
                }
            setSerCredits(updatedSerCredit)
        }
        setTabValue(SerCreditState.LOCKED_TO_MARKET)
        closeLockToMarketDialog()
        setLockToMarketFormData({
            serCredit: null,
            quantity: 0,
            price: 1
        })
    }

    const transfer = () => {
        if (transferFormData.quantity === transferFormData.serCredit?.quantity) {
            const updatedSerCredit = [...serCredits]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === transferFormData.serCredit?.id)] =
                {
                    ...transferFormData.serCredit!!,
                    state: SerCreditState.HELD,
                    clientId: transferFormData.to?.id!!
                }
            setSerCredits(updatedSerCredit)
        } else {
            const updatedSerCredit = [...serCredits,
                {
                    ...transferFormData.serCredit!!,
                    quantity: transferFormData.serCredit?.quantity!! - transferFormData.quantity
                }
            ]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === transferFormData.serCredit?.id)] =
                {
                    ...transferFormData.serCredit!!,
                    state: SerCreditState.HELD,
                    clientId: transferFormData.to?.id!!,
                    id: safCredits.length + 1,
                    quantity: transferFormData.quantity
                }
            setSerCredits(updatedSerCredit)
        }
        closeTransferDialog()
        setTabValue(SerCreditState.HELD)
        setTransferFormData({
            serCredit: null,
            quantity: 0,
            to: null
        })
    }


    return (
        <>
            <Typography variant='h2'>SAF End-user Reduction Credit Management</Typography>
            <Box sx={{marginBottom: sectionSpace}}>
                <Typography variant='h3'>
                    Summary
                </Typography>
                <TableContainer component={Paper} sx={{width: '520px'}}>
                    <Table size='small' aria-label="SER Credit summary table">
                        <TableHead>
                            <TableRow>

                                <TableCell key={SerCreditState.AVAILABLE} sx={{paddingLeft: 0}} align="right">
                                    {SerCreditState.AVAILABLE}
                                </TableCell>
                                <TableCell key={SerCreditState.HELD} sx={{paddingLeft: 0}} align="right">
                                    {SerCreditState.HELD}
                                </TableCell>
                                <TableCell key={SerCreditState.LOCKED_TO_MARKET} sx={{paddingLeft: 0}} align="right">
                                    {SerCreditState.LOCKED_TO_MARKET}
                                </TableCell>
                                <TableCell key={SerCreditState.RETIRED} sx={{paddingLeft: 0}} align="right">
                                    {SerCreditState.RETIRED}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell key={SerCreditState.AVAILABLE} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(serCredits)[SerCreditState.AVAILABLE]}
                                </TableCell>
                                <TableCell key={SerCreditState.HELD} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(serCredits)[SerCreditState.HELD]}
                                </TableCell>
                                <TableCell key={SerCreditState.LOCKED_TO_MARKET} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(serCredits)[SerCreditState.LOCKED_TO_MARKET]}
                                </TableCell>
                                <TableCell key={SerCreditState.RETIRED} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(serCredits)[SerCreditState.RETIRED]}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Typography variant="h3">
                Available, Held, Locked to Market Credits
            </Typography>
            <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">
                <Button
                    variant="outlined"
                    sx={{width: '10%'}}
                    onClick={openTransferDialog}
                >
                    Transfer
                </Button>
                {transferDialogActive &&
                    <Dialog
                        open={transferDialogActive}
                        onClose={closeTransferDialog}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                            <Typography variant='h2'>
                                Transfer
                            </Typography>
                            <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                                <Autocomplete
                                    id="lock-to-market-form-credit-select"
                                    options={serCredits.filter((credit) => credit.state === SerCreditState.AVAILABLE)}
                                    getOptionLabel={(credit) => `${getProductFromSerCredit(credit)?.name} | SER | ${credit.quantity} credits`}
                                    renderInput={(params) => <TextField {...params} label="SER Credit"/>}
                                    value={transferFormData.serCredit}
                                    onChange={(e, newValue) => {
                                        setTransferFormData(
                                            {
                                                ...transferFormData,
                                                serCredit: newValue,
                                                quantity: newValue !== null ? newValue.quantity : 0
                                            }
                                        )
                                    }}
                                />
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={transferFormData.quantity}
                                    onChange={(e) => {
                                        setTransferFormData({
                                            ...transferFormData,
                                            quantity: Number(e.target.value)
                                        })
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: transferFormData.serCredit?.quantity,
                                            min: 0
                                        }
                                    }} // Ensures a minimum value of 0
                                    variant="outlined"
                                />
                                <Autocomplete
                                    id="lock-to-market-form-client-select"
                                    options={clients}
                                    getOptionLabel={(client) => `${client.name}`}
                                    renderInput={(params) => <TextField {...params} label="To"/>}
                                    value={transferFormData.to}
                                    onChange={(e, newValue) => {
                                        setTransferFormData(
                                            {
                                                ...transferFormData,
                                                to: newValue
                                            }
                                        )
                                    }}
                                />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeTransferDialog} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                disabled={!transferFormData.serCredit || !transferFormData.to || transferFormData.quantity < 1 || transferFormData.quantity > transferFormData.serCredit.quantity}
                                onClick={transfer}
                                color="primary"
                                variant="outlined"
                            >
                                Transfer
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <Button
                    variant="outlined"
                    sx={{width: '10%'}}
                    onClick={openLockToMarketDialog}
                >
                    Lock To Market
                </Button>
                {lockToMarketDialogActive &&
                    <Dialog
                        open={lockToMarketDialogActive}
                        onClose={closeLockToMarketDialog}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                            <Typography variant='h2'>
                                Lock to Market
                            </Typography>
                            <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                                <Autocomplete
                                    id="lock-to-market-form-credit-select"
                                    options={serCredits.filter((credit) => credit.state === SerCreditState.AVAILABLE)}
                                    getOptionLabel={(credit) => `${getProductFromSerCredit(credit)?.name} | SER | ${credit.quantity} credits`}
                                    renderInput={(params) => <TextField {...params} label="SER Credit"/>}
                                    value={lockToMarketFormData.serCredit}
                                    onChange={(e, newValue) => {
                                        setLockToMarketFormData(
                                            {
                                                ...lockToMarketFormData,
                                                serCredit: newValue,
                                                quantity: newValue !== null ? newValue.quantity : 0
                                            }
                                        )
                                    }}
                                />
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={lockToMarketFormData.quantity}
                                    onChange={(e) => {
                                        setLockToMarketFormData({
                                            ...lockToMarketFormData,
                                            quantity: Number(e.target.value)
                                        })
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: lockToMarketFormData.serCredit?.quantity,
                                            min: 0
                                        }
                                    }} // Ensures a minimum value of 0
                                    variant="outlined"
                                />
                                <TextField
                                    label="Price"
                                    type="number"
                                    value={lockToMarketFormData.price}
                                    onChange={(e) => {
                                        setLockToMarketFormData({
                                            ...lockToMarketFormData,
                                            price: Number(e.target.value)
                                        })
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    variant="outlined"
                                />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeLockToMarketDialog} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                disabled={!lockToMarketFormData.serCredit || lockToMarketFormData.quantity < 1 || lockToMarketFormData.quantity > lockToMarketFormData.serCredit.quantity}
                                onClick={lockToMarket}
                                color="primary"
                                variant="outlined"
                            >
                                Lock
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Stack>
            <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 1}}>
                <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                >
                    <Tab
                        label="Available"
                        value={SerCreditState.AVAILABLE}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Held"
                        value={SerCreditState.HELD}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Locked To Market"
                        value={SerCreditState.LOCKED_TO_MARKET}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Retired"
                        value={SerCreditState.RETIRED}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                </Tabs>
            </Box>
            <SerCreditTable filterState={tabValue} withClient={true} setTabValue={setTabValue} withUnlockButton={true}/>
        </>
    )
}

export default SerManagement