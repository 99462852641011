import {atom} from "jotai";

export interface Client {
    id: number,
    name: string,
    walletValue: number
}

export const clientsAtom = atom<Client[]>([
    { id: 1, name: 'Client A', walletValue: 100000 },
    { id: 2, name: 'Client B', walletValue: 100000 },
    { id: 3, name: 'Client C', walletValue: 100000 },
    { id: 4, name: 'Client D', walletValue: 100000 },
])

export const selectedClientAtom = atom<Client | null>(null)