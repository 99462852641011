import {Client, clientsAtom} from "../../atom/ClientAtom";
import {useAtom} from "jotai";
import {Box, Typography} from "@mui/material";
import React, {useState} from "react";
import {sectionSpace} from "../../Themes";
import KpiBox from "../../component/KpiBox";
import ClientMain from "../../component/ClientMain";
import {formatNumberToPrice, formatWithCommans} from "../../utility/utils";
import {serCreditsAtom, SerCreditState} from "../../atom/SerCreditAtom";
import {flightsAtom} from "../../atom/FlightAtom";

const Home = () => {
    const [clients, setClients] = useAtom<Client[]>(clientsAtom)
    const [selectedClient, _setSelectedClient] = useState(clients[0])
    const [serCredits] = useAtom(serCreditsAtom)
    const [flights] = useAtom(flightsAtom)
    const getTotalAvailableCredits = () => {
        return serCredits
            .filter(serCredit => serCredit.clientId === selectedClient.id && serCredit.state === SerCreditState.HELD)
            .reduce((accumulator, serCredit) => accumulator + serCredit.quantity, 0)
    }

    const getTotalRetiredCredits = () => {
        return serCredits
            .filter(serCredit => serCredit.clientId === selectedClient.id && serCredit.state === SerCreditState.RETIRED)
            .reduce((accumulator, serCredit) => accumulator + serCredit.quantity, 0)
    }

    const getTotalEmissionsOffset = () => {
        return flights.filter(flight => flight.clientId === selectedClient.id && flight.isOffset)
            .reduce((accumulator, flight ) => accumulator + flight.emissions, 0)
    }
    return (
        <>
            <Typography variant='h2'>{selectedClient.name}'s Home</Typography>
            <Box sx={{display: "grid", gap: 2, gridTemplateColumns: 'repeat(4, 1fr)', marginBottom: sectionSpace,}}>
                <KpiBox title={"Balance (AUD)"} value={`$${formatNumberToPrice(selectedClient.walletValue)}`}/>
                <KpiBox title={"Total CO2e Offset (kg)"} value={`${formatWithCommans(getTotalEmissionsOffset())}`}/>
                <KpiBox title={"Available Credits"} value={`${getTotalAvailableCredits()}`}/>
                <KpiBox title={"Retired Credits"} value={`${getTotalRetiredCredits()}`}/>
            </Box>
            <ClientMain selectedClient={selectedClient} withCreditSummary={false}/>
        </>
    )
}

export default Home