import {useAtom} from "jotai";
import {Client, clientsAtom} from "../atom/ClientAtom";
import React, {useEffect, useState} from "react";
import {getSafCredit, SerCredit, serCreditsAtom, SerCreditState} from "../atom/SerCreditAtom";
import {SafCredit, safCreditsAtom} from "../atom/SafCreditAtom";
import {Flight, flightsAtom} from "../atom/FlightAtom";
import {DataGrid, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {customFormatDate} from "../utility/utils";
import {itemsAtom} from "../atom/ItemAtom";
import {Product, productsAtom} from "../atom/ProductAtom";
import {useLayoutState} from "../state/Layout";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {sectionSpace} from "../Themes";
import SerCreditTable from "../view/serManagement/SerCreditTable";
import 'react-day-picker/dist/style.css';
import DateRangePicker from "./DateRangePicker";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DateRange} from "react-day-picker";
import dayjs from "dayjs";
import SerCreditSummaryByStandard from "./SerCreditSummaryByStandard";
import DoneIcon from '@mui/icons-material/Done';

const ClientMain = ({selectedClient, withCreditSummary}: {
    selectedClient: Client | null,
    withCreditSummary: boolean
}) => {
    const [clients, setClients] = useAtom(clientsAtom)
    const [offsetDialogActive, setOffsetDialogActive] = useState<boolean>(false);
    const [addFlightsDialogActive, setAddFlightsDialogActive] = useState<boolean>(false);
    const [serCredits, setSerCredits] = useAtom(serCreditsAtom)
    const [safCredits] = useAtom(safCreditsAtom)
    const [flights, setFlights] = useAtom(flightsAtom)
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });
    const [range, setRange] = useState<DateRange | undefined>();

    interface OffsetFormData {
        flights: Flight[],
        serCredit: SerCredit | null
    }

    const getClientFlights = (): Flight[] => {
        if (selectedClient === null) {
            return []
        }

        return flights.filter((flight) => flight.clientId === selectedClient.id)
    }
    const [offsetFormData, setOffsetFormData] = useState<OffsetFormData>({
        flights: [],
        serCredit: null
    })

    useEffect(() => {
        setOffsetFormData({
            flights: [],
            serCredit: null
        })
        setRange(undefined)
    }, [selectedClient]);

    const [tabValue, setTabValue] = useState<'credits' | 'retired' | 'flights'>('credits');
    const [loading, setLoading] = useState<boolean>()


    const openOffsetDialog = () => {
        setOffsetDialogActive(true);
    };

    const closeOffsetDialog = () => {
        setOffsetDialogActive(false)
    }

    const openOffsetDialogAndSelectFlight = (flight: Flight) => {
        setOffsetFormData({
            ...offsetFormData,
            flights: [flight]
        })
        setOffsetDialogActive(true);
    }


    const [items] = useAtom(itemsAtom)
    const [products] = useAtom(productsAtom)
    const getProductFromSerCredit = (credit: SerCredit): Product | null => {
        const safCredit = safCredits.find((safCredit => safCredit.id === credit.safCreditId))
        const item = items.find(item => item.id === safCredit?.itemId)
        const product = products.find(product => product.id === item?.productId)
        return product ?? null
    }


    const getHeldClientCredits = (): SerCredit[] => {
        if (selectedClient === null) {
            return []
        }

        return serCredits.filter((serCredit) => serCredit.clientId === selectedClient.id && serCredit.state === SerCreditState.HELD)
    }

    const getRetiredClientCredits = (): SerCredit[] => {
        if (selectedClient === null) {
            return []
        }

        return serCredits.filter((serCredit) => serCredit.clientId === selectedClient.id && serCredit.state === SerCreditState.RETIRED)
    }

    const {customTheme} = useLayoutState();

    const hoverTabTextColor = {color: customTheme.theme.palette.secondary.main};
    const onTabChange = (event: React.SyntheticEvent, value: any): void => {
        setTabValue(value);
    }

    const getOffsetFormTotalEmissions = () => offsetFormData.flights.reduce((total, flight) => total + flight.emissions, 0)

    const getSafCreditFromSerCredit = (credit: SerCredit): SafCredit | null => {

        return safCredits.find(safCredit => safCredit.id === credit.safCreditId) ?? null
    }
    const flightTable = () => {
        return (
            <Box style={{width: '100%', height: 'auto', overflow: 'auto', marginTop: "10px"}}>
                <DataGrid
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '22px'
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }
                    }}
                    slots={{toolbar: GridToolbar}}
                    rows={getClientFlights()}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    filterModel={filterModel}
                    onFilterModelChange={(newModel) => setFilterModel(newModel)}
                    loading={loading}
                    density="compact"
                    disableDensitySelector
                    aria-label="flights table"
                    columns={[
                        {
                            field: 'date',
                            headerName: "Date",
                            flex: 1,
                            renderCell: (params) => {
                                const date = params.value as Date
                                return (<>{customFormatDate(date)}</>)
                            }
                        },
                        {
                            field: 'origin',
                            headerName: "Origin",
                            flex: 1
                        },
                        {
                            field: 'destination',
                            headerName: "Destination",
                            flex: 1
                        },
                        {
                            field: 'flightNum',
                            headerName: "Flight No.",
                        },
                        {
                            field: 'emissions',
                            headerName: "CO2e (kg)",
                        },
                        {
                            field: 'action',
                            headerName: '',
                            filterable: false,
                            sortable: false,
                            disableExport: true,
                            align: 'right',
                            minWidth: 200,
                            renderCell: (params) => {
                                const flight = params.row;
                                return (
                                    <Stack direction={"row"} gap={1} alignItems="center">
                                        {flight.date > new Date && <Chip label="Upcoming" size={"small"}/>}
                                        {!flight.isOffset ?
                                            <Button variant="outlined"
                                                    onClick={() => openOffsetDialogAndSelectFlight(params.row)}>
                                                Offset
                                            </Button>
                                            :
                                            <Chip icon={<DoneIcon/>} label="Offset" size={"small"} color="success"/>
                                        }

                                    </Stack>
                                );
                            }
                        }

                    ]}

                />
            </Box>)
    }
    const offset = () => {
        let updatedFlights = [...flights]
        updatedFlights = updatedFlights.map(flight => {
            if (offsetFormData.flights.map(formFlight => formFlight.id).includes(flight.id)) {
                return {
                    ...flight,
                    isOffset: true
                }
            } else return flight
        })
        setFlights(updatedFlights)
        const creditQuantity = Math.ceil(getOffsetFormTotalEmissions() / 1000)
        if (creditQuantity === offsetFormData.serCredit?.quantity) {
            const updatedSerCredit = [...serCredits]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === offsetFormData.serCredit?.id)] =
                {
                    ...offsetFormData.serCredit!!,
                    state: SerCreditState.RETIRED,
                    retiredDate: new Date()
                }
            setSerCredits(updatedSerCredit)
        } else {
            const updatedSerCredit = [...serCredits,
                {
                    ...offsetFormData.serCredit!!,
                    quantity: offsetFormData.serCredit?.quantity!! - creditQuantity
                }
            ]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === offsetFormData.serCredit?.id)] =
                {
                    ...offsetFormData.serCredit!!,
                    state: SerCreditState.RETIRED,
                    id: safCredits.length + 1,
                    retiredDate: new Date(),
                    quantity: creditQuantity
                }
            setSerCredits(updatedSerCredit)
        }


        closeOffsetDialog()
        setOffsetFormData({
            serCredit: null,
            flights: [],
        })
    }

    const flightWithinRange = (flight: Flight, range: DateRange): boolean | null => {
        if (!range || !range.from) {
            return null
        }

        if (!range.to) {
            return dayjs(flight.date).isSame(range.from, 'day')
        }
        const curr = dayjs(flight.date)
        const from = dayjs(range.from)
        const to = dayjs(range.to)
        return curr.isAfter(from.startOf('day')) && curr.isBefore(to.endOf('day'))
    }

    const handleSetRange = (newRange: DateRange | undefined) => {
        setRange(newRange);
        setOffsetFormData(
            {
                ...offsetFormData,
                flights: !newRange ? [] : flights.filter((flight) => !flight.isOffset && flightWithinRange(flight, newRange))
            }
        )
    };

    return (
        <>
            {selectedClient &&
                <>
                    {withCreditSummary &&
                        <Box sx={{marginBottom: sectionSpace}}>
                            <SerCreditSummaryByStandard serCredits={serCredits.filter((credit) => credit.clientId === selectedClient.id && credit.state === SerCreditState.HELD)}/>
                        </Box>
                    }
                    <Box sx={{marginBottom: sectionSpace}}>
                        <Typography variant='h3'>
                            SER Credit Holdings, Retired Credits, & Flights
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" onClick={openOffsetDialog}>Offset</Button>
                        <Button variant={"outlined"} onClick={() => setAddFlightsDialogActive(true)}>Add
                            Flights</Button>

                    </Stack>
                    {offsetDialogActive && (
                        <Dialog
                            open={offsetDialogActive}
                            onClose={closeOffsetDialog}
                            fullWidth
                            maxWidth="sm">
                            <DialogContent>
                                <Typography variant='h2'>
                                    Offset Flight
                                </Typography>
                                <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                                    <Accordion defaultExpanded sx={{backgroundColor: "inherit"}}>
                                        <AccordionSummary id="date-filter-header" aria-controls="date-filter-content"
                                                          expandIcon={<ExpandMoreIcon/>}>
                                            <span style={{
                                                marginRight: "15px",
                                                fontWeight: "bold"
                                            }}>Filter Flights:</span>
                                            <span>{range?.from ? dayjs(range?.from).format('DD/MM/YYYY') : "from"}</span>
                                            <span style={{marginInline: "10px"}}>-</span>
                                            <span>{range?.to ? dayjs(range?.to).format('DD/MM/YYYY') : "to"}</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DateRangePicker range={range} setRange={handleSetRange}/>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Autocomplete
                                        id="offset-flights-select"
                                        multiple
                                        options={getClientFlights().filter(flight => !flight.isOffset)}
                                        getOptionLabel={(flight) => `${customFormatDate(flight.date)} | ${flight.flightNum} | ${flight.origin} -> ${flight.destination} | ${flight.emissions} kg CO2e`}
                                        renderInput={(params) => <TextField {...params} label="Flights"/>}
                                        value={offsetFormData.flights}
                                        onChange={(e, newValue) => {
                                            setOffsetFormData(
                                                {
                                                    ...offsetFormData,
                                                    flights: newValue
                                                }
                                            )
                                        }}
                                        getOptionDisabled={(flight) => {
                                            if (!range || !range.from) {
                                                return false
                                            }
                                            return !flightWithinRange(flight, range)
                                        }}
                                    />
                                    {offsetFormData.flights.length > 0 &&
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            mt={1}
                                            mb={1}
                                            sx={{textAlign: 'right'}}
                                        >
                                            {`Total Emissions: ${getOffsetFormTotalEmissions()} kg CO2e  = ${Math.ceil(getOffsetFormTotalEmissions() / 1000)} credits`}
                                        </Typography>
                                    }
                                    <Autocomplete
                                        id="offset-form-credit-select"
                                        options={getHeldClientCredits()}
                                        getOptionLabel={(credit) => `${getProductFromSerCredit(credit)?.name} | ${getSafCredit(credit, safCredits).standard} | ${credit.quantity} credits`}
                                        renderInput={(params) => <TextField {...params} label="SER Credit"/>}
                                        value={offsetFormData.serCredit}
                                        onChange={(e, newValue) => {
                                            setOffsetFormData(
                                                {
                                                    ...offsetFormData,
                                                    serCredit: newValue
                                                }
                                            )
                                        }}
                                    />
                                    {offsetFormData.serCredit && offsetFormData.serCredit.quantity < Math.ceil(getOffsetFormTotalEmissions() / 1000) &&
                                        <Typography
                                            variant="caption"
                                            color="error"
                                            mt={1}
                                            mb={1}
                                            sx={{textAlign: 'right'}}
                                        >
                                            Insufficient Credits
                                        </Typography>}
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeOffsetDialog} color="primary" variant="outlined">
                                    Cancel
                                </Button>
                                <Button
                                    disabled={!offsetFormData.serCredit || offsetFormData.flights.length === 0 || offsetFormData.serCredit.quantity < Math.ceil(getOffsetFormTotalEmissions() / 1000)}
                                    onClick={offset}
                                    color="primary"
                                    variant="outlined"
                                >
                                    Offset
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {addFlightsDialogActive && (
                        <Dialog
                            open={addFlightsDialogActive}
                            onClose={() => setAddFlightsDialogActive(false)}
                            fullWidth
                            maxWidth="sm">
                            <DialogContent>
                                <Typography variant='h2'>
                                    Add Flight
                                </Typography>
                                <Button>
                                    Import CSV
                                </Button>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setAddFlightsDialogActive(false)} color="primary"
                                        variant="outlined">
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 1}}>
                        <Tabs
                            value={tabValue}
                            onChange={onTabChange}
                        >
                            <Tab
                                label="Credits"
                                value={'credits'}
                                sx={{
                                    ...hoverTabTextColor,
                                    transition: customTheme.customProps.navHoverTransition,
                                    '&:hover': {
                                        backgroundColor: customTheme.customProps.navHoverBackgroundColor
                                    }
                                }}
                            />
                            <Tab
                                label="Retired"
                                value={'retired'}
                                sx={{
                                    ...hoverTabTextColor,
                                    transition: customTheme.customProps.navHoverTransition,
                                    '&:hover': {
                                        backgroundColor: customTheme.customProps.navHoverBackgroundColor
                                    }
                                }}
                            />
                            <Tab
                                label="Flights"
                                value={'flights'}
                                sx={{
                                    ...hoverTabTextColor,
                                    transition: customTheme.customProps.navHoverTransition,
                                    '&:hover': {
                                        backgroundColor: customTheme.customProps.navHoverBackgroundColor
                                    }
                                }}
                            />

                        </Tabs>
                    </Box>
                    {
                        tabValue === 'credits' &&
                        <SerCreditTable filterClient={selectedClient} filterState={SerCreditState.HELD}/>
                    }
                    {
                        tabValue === 'retired' &&
                        <SerCreditTable filterClient={selectedClient} filterState={SerCreditState.RETIRED}/>
                    }
                    {
                        tabValue === 'flights' && flightTable()
                    }
                </>
            }
        </>
    )
}

export default ClientMain