import {atom} from "jotai";

export interface Flight {
    id: number,
    clientId: number,
    origin: string,
    destination: string,
    flightNum: string,
    emissions: number, // in kg
    date: Date,
    isOffset: boolean
}

export const flightsAtom = atom<Flight[]>([
    {
        id: 1,
        clientId: 1,
        origin: "London Heathrow (LHR)",
        destination: "Paris Charles de Gaulle (CDG)",
        flightNum: "LH123",
        emissions: 554,
        date: new Date('2024-02-01'),
        isOffset: false,
    },
    {
        id: 2,
        clientId: 1,
        origin: "New York (JFK)",
        destination: "Miami International Airport (MIA)",
        flightNum: "NY456",
        emissions: 1753,
        date: new Date('2024-02-10'),
        isOffset: false,
    },
    {
        id: 3,
        clientId: 1,
        origin: "Los Angeles International Airport (LAX)",
        destination: "Tokyo Narita Airport (NRT)",
        flightNum: "LA789",
        emissions: 8789,
        date: new Date('2024-02-27'),
        isOffset: false,
    },
    {
        id: 4,
        clientId: 1,
        origin: "Sydney Kingsford Smith Airport (SYD)",
        destination: "London Heathrow (LHR)",
        flightNum: "Private Jet",
        emissions: 17018,
        date: new Date('2024-02-29T00:00:00'),
        isOffset: false,
    },
])