import {getGridStringOperators} from "@mui/x-data-grid";
import {useAtom} from "jotai";
import {Product, productsAtom} from "../atom/ProductAtom";
import {clientsAtom} from "../atom/ClientAtom";
import {flightsAtom} from "../atom/FlightAtom";
import {itemsAtom} from "../atom/ItemAtom";
import {
    CreditStandard,
    getStandardCarbonIntensityCombustion,
    getStandardCarbonIntensityLifecycle,
    safCreditsAtom,
    SafCreditState
} from "../atom/SafCreditAtom";
import {serCreditsAtom} from "../atom/SerCreditAtom";
import dayjs from "dayjs";


const stringFilterOperators = getGridStringOperators().filter(
    (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
);

export default {
    stringFilterOperators
}

export function customFormatDate(date: Date) {
    const parsedDate = dayjs(date);
    return parsedDate.format('DD MMM YYYY hh:mm A');
}

export const mapEnumToZeroValues = (enumObject: any): Record<string, number> => {
    const result: Record<string, number> = {};

    Object.keys(enumObject).forEach((key) => {
        if (isNaN(Number(enumObject[key]))) {
            result[enumObject[key]] = 0;
        }
    });

    return result;
}

export const useCheckAtom = () => {
    const [products] = useAtom(productsAtom)
    const [clients] = useAtom(clientsAtom)
    const [flights] = useAtom(flightsAtom)
    const [items] = useAtom(itemsAtom)
    const [safCredits] = useAtom(safCreditsAtom)
    const [serCredits] = useAtom(serCreditsAtom)

    function getNonSubsetItems<T>(subset: T[], array: T[]): T[] {
        return subset.filter(item => !array.includes(item));
    }

    // item -> product
    const itemsError = getNonSubsetItems(items.map(item => item.productId), products.map(product => product.id))

    const errorItems = items.filter(item => safCredits.map(credit => credit.itemId).includes(item.id) && !item.consumed)
    console.log(`Error items: ${JSON.stringify(errorItems, null, 4)}`)

    // safCredit -> item
    const safCreditError = getNonSubsetItems(safCredits.map(credit => credit.itemId), items.map(item => item.id))

    // serCredit -> safCredit
    const serCreditErrorToSafCredit = getNonSubsetItems(serCredits.map(credit => credit.safCreditId), items.map(safCredits => safCredits.id))
    const serCreditErrorToClients = getNonSubsetItems(serCredits.map(credit => credit.clientId), clients.map(client => client.id))

    const checkSafCreditRetired = safCredits.filter(safCredit => serCredits.map(serCredit => serCredit.safCreditId).includes(safCredit.id))
        .some(safCredit => safCredit.state !== SafCreditState.RETIRED)
    console.log(`SAF Credit RETIRED state incorrect: ${checkSafCreditRetired}`)

    console.log("error", itemsError, safCreditError, serCreditErrorToSafCredit, serCreditErrorToClients)

}
/**
 * Returns the quantity of SAF credits that will be generated from consuming fuel
 * @param productCarbonIntensityCombustion An index of the amount of carbon dioxide gas emitted in tonnes per MJ of energy generated when fuel is combusted. unit: tCO2e/MJ
 * @param productDensity unit: kg/m3
 * @param productEnergyContent unit: MJ/kg
 * @param productQuantity unit: L
 * @param standard standard used for comparative analysis. represents the amount of carbon dioxide emitted from combustion of a conventional fuel
 */
export const calculateCreditsGenerated = (productCarbonIntensityCombustion: number, productDensity: number, productEnergyContent: number, productQuantity: number, standard: CreditStandard): number => {

    const totalEnergy = getTotalEnergy(productDensity, productEnergyContent, productQuantity) // MJ
    const standardEmissions = getCarbonEmission(getStandardCarbonIntensityCombustion(standard), totalEnergy) //
    const safEmissions = getCarbonEmission(productCarbonIntensityCombustion, totalEnergy)
    return Math.floor(standardEmissions - safEmissions)
}

/**
 * Returns the total energy in MJ for a fuel
 *
 * @param productDensity unit: kg/m3
 * @param productEnergyContent unit: MJ/kg
 * @param productQuantity unit: L
 */
export const getTotalEnergy = (productDensity: number, productEnergyContent: number, productQuantity: number): number => {
    return productDensity * productEnergyContent * productQuantity / 1000 //1 m3 = 1000 L
}

/**
 * Returns the eCO2 emitted in tonnes
 * @param carbonIntensity unit: g eCO2/ MJ
 * @param totalEnergy unit: MJ
 */
export const getCarbonEmission = (carbonIntensity: number, totalEnergy: number): number => {
    return carbonIntensity * totalEnergy / 1000000 //1 tonne = 100000 g
}

export const getCarbonEmissions = (product: Product, quantity: number): {
    emissionsLifecycle: number,
    emissionsCombustion: number
} => {
    const totalEnergy = getTotalEnergy(product.density, product.energyContent, quantity)
    const safEmissionLifecycle = getCarbonEmission(product.carbonIntensityLifecycle, totalEnergy)
    const safEmissionCombustion = getCarbonEmission(product.carbonIntensityCombustion, totalEnergy)

    return {
        emissionsLifecycle: safEmissionLifecycle,
        emissionsCombustion: safEmissionCombustion
    }
}

export const getEmissionAbatedDetails =
    (product: Product, quantity: number, standard: CreditStandard): {
        emissionsAbatedLifecycle: number,
        emissionsAbatedCombustion: number,
        emissionsReductionLifecycle: number
    } => {
        const totalEnergy = getTotalEnergy(product.density, product.energyContent, quantity)
        const standardEmissionLifecycle = getCarbonEmission(getStandardCarbonIntensityLifecycle(standard), totalEnergy) //
        const safEmissionLifecycle = getCarbonEmission(product.carbonIntensityLifecycle, totalEnergy)

        const standardEmissionCombustion = getCarbonEmission(getStandardCarbonIntensityCombustion(standard), totalEnergy) //
        const safEmissionCombustion = getCarbonEmission(product.carbonIntensityCombustion, totalEnergy)
        return {
            emissionsAbatedLifecycle: standardEmissionLifecycle - safEmissionLifecycle,
            emissionsAbatedCombustion: standardEmissionCombustion - safEmissionCombustion,
            emissionsReductionLifecycle: (standardEmissionLifecycle - safEmissionLifecycle) / standardEmissionLifecycle * 100
        }
    }

export function formatWithCommans(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberToPrice(number: number) {
    // Convert number to string and round to 2 decimal places
    const roundedNumber = number.toFixed(2);

    return roundedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

