import {Item} from "../../../atom/ItemAtom";
import {SerCredit} from "../../../atom/SerCreditAtom";
import {SafCredit} from "../../../atom/SafCreditAtom";

export const getInventoryHolding = (productId: number, items: Item[]): number =>
    items.filter((item) => item.productId === productId).reduce((total, item) =>
        total + item.quantity, 0)

export const getCreditId = (itemId: number, safCredits: SafCredit[]): number | undefined =>
    safCredits.find((safCredit) => safCredit.itemId === itemId)?.id
