import {atom} from "jotai/index";
import {items as itemList} from "./ItemAtom";
import {products} from "./ProductAtom";

export enum CreditStandard {
    CORSIA = "CORSIA", //95 kg CO2e
    RED_II = "RED II", //92 kg CO2e
    US_STANDARD = "US Standard", //93 kg CO2e
}

export const getStandardCarbonIntensityCombustion = (standard: CreditStandard) => {
    switch (standard) {
        case CreditStandard.CORSIA:
            return 92.7
        case CreditStandard.RED_II:
            return 92
        case CreditStandard.US_STANDARD:
            return 93

    }
}

export const getStandardCarbonIntensityLifecycle = (standard: CreditStandard) => {
    switch (standard) {
        case CreditStandard.CORSIA:
            return 115.36
        case CreditStandard.RED_II:
            return 117
        case CreditStandard.US_STANDARD:
            return 116
    }
}

export enum SafCreditState {
    LOCKED_TO_MARKET = "Locked To Market",
    AVAILABLE = "Available",
    RETIRED = "Retired"
}

export interface SafCredit {
    id: number,
    name: string,
    itemId: number, //relation to item
    quantity: number,
    standard: CreditStandard,
    state: SafCreditState,
    createdAt: Date,
    retiredDate: Date | null,
    location: string,
}


export const safCredits: SafCredit[] = [
    {
        id: 1,
        name: "SAF Credit",
        itemId: 1,
        quantity: 806,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 2,
        name: "SAF Credit",
        itemId: 2,
        quantity: 806,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 3,
        name: "SAF Credit",
        itemId: 3,
        quantity: 806,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 4,
        name: "SAF Credit",
        itemId: 4,
        quantity: 806,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 5,
        name: "SAF Credit",
        itemId: 5,
        quantity: 806,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 6,
        name: "SAF Credit",
        itemId: 6,
        quantity: 806,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 7,
        name: "SAF Credit",
        itemId: 7,
        quantity: 403,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 8,
        name: "SAF Credit",
        itemId: 8,
        quantity: 322,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 9,
        name: "SAF Credit",
        itemId: 9,
        quantity: 483,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 10,
        name: "SAF Credit",
        itemId: 10,
        quantity: 322,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 11,
        name: "SAF Credit",
        itemId: 11,
        quantity: 322,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2024-01-01T00:00:00'),
        location: "Sydney"
    },
    {
        id: 12,
        name: "SAF Credit",
        itemId: 12,
        quantity: 244,
        standard: CreditStandard.RED_II,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2023-10-30T00:00:00.000Z'),
        location: "Sydney"
    },
    {
        id: 13,
        name: "SAF Credit",
        itemId: 13,
        quantity: 244,
        standard: CreditStandard.RED_II,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2023-10-30T00:00:00.000Z'),
        location: "Sydney"
    },
    {
        id: 14,
        name: "SAF Credit",
        itemId: 14,
        quantity: 247,
        standard: CreditStandard.US_STANDARD,
        state: SafCreditState.RETIRED,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2023-10-30T00:00:00.000Z'),
        location: "Sydney"
    },
    {
        id: 15,
        name: "SAF Credit",
        itemId: 15,
        quantity: 247,
        standard: CreditStandard.US_STANDARD,
        state: SafCreditState.AVAILABLE,
        createdAt: new Date('2023-11-23T01:59:36.277Z'),
        retiredDate: new Date('2023-10-30T00:00:00.000Z'),
        location: "Sydney"
    },
    {
        id: 16,
        name: "Algae-based Oil Credit",
        itemId: 17,
        quantity: 1198,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.AVAILABLE,
        createdAt: new Date('2024-05-10T08:00:00Z'),
        retiredDate: null,
        location: 'Vantaa, Finland'
    },
    {
        id: 17,
        name: "Sugarcane Extract Credit",
        itemId: 18,
        quantity: 3292,
        standard: CreditStandard.RED_II,
        state: SafCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2024-05-15T08:00:00Z'),
        retiredDate: null,
        location: 'Tampere, Finland'
    },
    {
        id: 18,
        name: "Corn-based Oil Credit",
        itemId: 19,
        quantity: 7933,
        standard: CreditStandard.US_STANDARD,
        state: SafCreditState.AVAILABLE,
        createdAt: new Date('2024-05-20T08:00:00Z'),
        retiredDate: null,
        location: 'Oulu, Finland'
    },
    {
        id: 19,
        name: "Plant-derived Oils Credit",
        itemId: 20,
        quantity: 921,
        standard: CreditStandard.CORSIA,
        state: SafCreditState.LOCKED_TO_MARKET,
        createdAt: new Date('2024-05-25T08:00:00Z'),
        retiredDate: null,
        location: 'Turku, Finland'
    },
    {
        id: 20,
        name: "Refined Used Cooking Oil Credit",
        itemId: 21,
        quantity: 7283,
        standard: CreditStandard.RED_II,
        state: SafCreditState.AVAILABLE,
        createdAt: new Date('2024-05-30T08:00:00Z'),
        retiredDate: null,
        location: 'Lahti, Finland'
    }
]
//     [
//     {
//         id: 1,
//         name: "SAF Credit 1",
//         itemId: 1,
//         quantity: 261,
//         standard: CreditStandard.CORSIA,
//         state: SafCreditState.AVAILABLE,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 2,
//         name: "SAF Credit 2",
//         itemId: 2,
//         quantity: 97,
//         standard: CreditStandard.STANDARD_B,
//         state: SafCreditState.AVAILABLE,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 3,
//         name: "SAF Credit 3",
//         itemId: 5,
//         quantity: 176,
//         standard: CreditStandard.STANDARD_C,
//         state: SafCreditState.LOCKED_TO_MARKET,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 4,
//         name: "SAF Credit 4",
//         itemId: 7,
//         quantity: 142,
//         standard: CreditStandard.CORSIA,
//         state: SafCreditState.LOCKED_TO_MARKET,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: null,
//         location: "Sydney"
//     },
//     {
//         id: 5,
//         name: "SAF Credit 5",
//         itemId: 23,
//         quantity: 153,
//         standard: CreditStandard.STANDARD_D,
//         state: SafCreditState.AVAILABLE,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: null,
//         location: "Singapore"
//     },
//     {
//         id: 6,
//         name: "SAF Credit 6",
//         itemId: 24,
//         quantity: 175,
//         standard: CreditStandard.STANDARD_D,
//         state: SafCreditState.AVAILABLE,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: null,
//         location: "Indonesia"
//     },
//     {
//         id: 7,
//         name: "SAF Credit 7",
//         itemId: 32,
//         quantity: 210,
//         standard: CreditStandard.STANDARD_C,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 8,
//         name: "SAF Credit 8",
//         itemId: 35,
//         quantity: 239,
//         standard: CreditStandard.STANDARD_B,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 9,
//         name: "SAF Credit 9",
//         itemId: 37,
//         quantity: 140,
//         standard: CreditStandard.STANDARD_C,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 10,
//         name: "SAF Credit 10",
//         itemId: 42,
//         quantity: 12,
//         standard: CreditStandard.STANDARD_D,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 11,
//         name: "SAF Credit 11",
//         itemId: 69,
//         quantity: 69,
//         standard: CreditStandard.STANDARD_D,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Singapore"
//     },
//     {
//         id: 12,
//         name: "SAF Credit 12",
//         itemId: 88,
//         quantity: 167,
//         standard: CreditStandard.STANDARD_D,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Indonesia"
//     },
//     {
//         id: 13,
//         name: "SAF Credit 13",
//         itemId: 89,
//         quantity: 208,
//         standard: CreditStandard.STANDARD_D,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 14,
//         name: "SAF Credit 14",
//         itemId: 90,
//         quantity: 165,
//         standard: CreditStandard.STANDARD_B,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 15,
//         name: "SAF Credit 15",
//         itemId: 91,
//         quantity: 123,
//         standard: CreditStandard.STANDARD_C,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     },
//     {
//         id: 16,
//         name: "SAF Credit 16",
//         itemId: 99,
//         quantity: 95,
//         standard: CreditStandard.CORSIA,
//         state: SafCreditState.RETIRED,
//         createdAt: new Date('2023-11-23T01:59:36.277Z'),
//         retiredDate: new Date('2023-10-30T00:00:00.000Z'),
//         location: "Sydney"
//     }
// ]
export const safCreditsAtom = atom<SafCredit[]>(safCredits)


// function convertToEnumString<T extends Record<string, string>>(
//     keyName: string,
//     item: Record<string, any>,
//     enumObj: T,
//     enumName: string
// ): string {
//     return `${keyName}: ${enumName}.${Object.keys(enumObj)
//         .find(enumKey => enumObj[enumKey as keyof T] === item[keyName])}`;
// }
//
// function print(items: any[]) {
//     // let currId = items.length
//     // for (let i = 0; i < 10; i++) {
//     //     currId++
//     //     const state = Object.values(SafCreditState)[Math.floor(Math.random() * Object.values(SafCreditState).length)]
//     //     items.push({
//     //         id: currId,
//     //         name: `SAF Credit ${currId}`,
//     //         itemId: Math.ceil(Math.random() * 100),
//     //         quantity: 100 + Math.ceil(Math.random() * 900),
//     //         standard: Object.values(CreditStandard)[Math.floor(Math.random() * Object.values(CreditStandard).length)],
//     //         state: state,
//     //         createdAt: new Date('2023-11-01'),
//     //         retiredDate: null,
//     //         location: "Sydney"
//     //     })
//     // }
//     const str = items.map((credit: any) => {
//         const item = itemList.find(actualItem => actualItem.id === credit.id)!!
//         const product = products.find(product => product.id === item.id)!!
//         credit.quantity = Math.ceil((getStandardCarbonIntensityCombustion(credit.standard) - product.emissionFactor) * item.quantity * product.energyDensity / 1000000)
//
//         let substr = "{"
//         //add new field
//         if (credit.state === SafCreditState.RETIRED) {
//             credit.retiredDate = new Date('2023-10-30')
//         }
//
//         substr += Object.keys(credit).map((key) => {
//             if (Object.values(SafCreditState).includes(credit[key])) {
//                 return convertToEnumString(key, credit, SafCreditState, "SafCreditState")
//             }
//             if (Object.values(CreditStandard).includes(credit[key])) {
//                 return convertToEnumString(key, credit, CreditStandard, "CreditStandard")
//             }
//
//             if (credit[key] instanceof Date) {
//                 return ` ${key}: new Date('${credit[key].toISOString()}') `
//             }
//             if (typeof credit[key] === "string") {
//                 return ` ${key}: "${credit[key]}" `
//             }
//
//             return ` ${key}: ${credit[key]} `
//         }).join(',\n')
//         substr += "}"
//         return substr
//     }).join(',\n');
//     console.log(`[\n${str}\n]`);
// }
//
// print(safCredits)

