import {Button, Dialog, DialogActions, DialogContent, FormControl, TextField, Typography} from "@mui/material";
import React from "react";

export interface AddFuelFormData {
    name: string
}

export const getDefaultAddFuelFormData = (): AddFuelFormData => {
    return {
        name: ""
    }
}

const AddFuelForm = ({
                         addFuelDialogActive,
                         closeAddFuelDialog,
                         formData,
                         setFormData
                     }: {
    addFuelDialogActive: boolean,
    closeAddFuelDialog: any,
    formData: AddFuelFormData,
    setFormData: React.Dispatch<React.SetStateAction<AddFuelFormData>>,
}) => {
    return (
        <Dialog open={addFuelDialogActive} onClose={closeAddFuelDialog} fullWidth maxWidth={"sm"}>
            <DialogContent>
                <Typography variant='h2'>
                    Add Sustainable Aviation Fuel
                </Typography>
                <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                    <TextField
                        label="Name"
                        value={formData.name}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                name: e.target.value
                            })
                        }}
                        variant="outlined"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeAddFuelDialog} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                    }}
                    color="primary"
                    variant="outlined"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddFuelForm