import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {useAtom} from "jotai";
import {Item, itemsAtom} from "../../atom/ItemAtom";
import {
    CreditStandard,
    getStandardCarbonIntensityCombustion,
    safCreditsAtom,
    SafCreditState
} from "../../atom/SafCreditAtom";
import {calculateCreditsGenerated} from "../../utility/utils";

export interface GenerateCreditFormData {
    product: Product | null
    item: Item | null
    quantity: number
    name: string,
    standard: CreditStandard | null
}

export const getDefaultGenerateCreditFormData = (product?: Product): GenerateCreditFormData => {
    return {
        product: product ?? null,
        item: null,
        quantity: 0,
        name: "",
        standard: null,
    }
}

const GenerateCreditForm = ({
                                generateCreditDialogActive,
                                closeGenerateCreditDialog,
                                formData,
                                setFormData,
                                productSelectDisabled = false
                            }: {
    generateCreditDialogActive: boolean,
    closeGenerateCreditDialog: () => void;
    formData: GenerateCreditFormData,
    setFormData: React.Dispatch<React.SetStateAction<GenerateCreditFormData>>,
    productSelectDisabled?: boolean

}) => {
    const [products] = useAtom(productsAtom)
    const [items, setItems] = useAtom(itemsAtom)
    const [safCredits, setSafCredits] = useAtom(safCreditsAtom)
    const getAvailableItemsByProductId = (productId?: number) => items.filter((item) => item.productId === productId && !safCredits.some((safCredit) => safCredit.itemId === item.id))


    const generateCredit = () => {
        setSafCredits([...safCredits, {
            id: safCredits.length + 1,
            name: `SAF Credit ${safCredits.length + 1}`,
            itemId: formData.item!!.id,
            quantity: calculateCreditsGenerated(formData.product!!.carbonIntensityCombustion, formData.product!!.density, formData.product!!.energyContent, formData.quantity, formData.standard!!),
            standard: formData.standard!!,
            state: SafCreditState.AVAILABLE,
            createdAt: new Date,
            retiredDate: null,
            location: "Singapore",
        }])
        let resultItems = [...items]
        if (formData.quantity === formData.item?.quantity) {
            resultItems = resultItems.map(item => {
                if (item === formData.item) {
                    return {
                        ...item,
                        consumed: true
                    }
                }
                return item
            })
        } else {
            const diff = formData.item?.quantity!! - formData.quantity
            resultItems = resultItems.map(item => {
                if (item.id === formData.item!!.id) {
                    return {
                        ...item,
                        consumed: true,
                        quantity: formData.quantity
                    }
                }
                return item
            })
            resultItems.push({
                ...formData.item!!,
                id: resultItems.length + 1,
                quantity: diff
            })
        }
        setItems(resultItems)
        closeGenerateCreditDialog()
        setFormData(getDefaultGenerateCreditFormData())
    }

    return (
        <Dialog
            open={generateCreditDialogActive}
            onClose={closeGenerateCreditDialog}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent>
                <Typography variant='h2'>
                    Generate SAF Credit
                </Typography>
                <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                    <Autocomplete
                        id="generate-ser-credit-form-product-select"
                        disabled={productSelectDisabled}
                        options={products}
                        getOptionLabel={(product) => `${product.code} | ${product.name}`}
                        renderInput={(params) => <TextField {...params} label="Product"/>}
                        value={formData.product}
                        onChange={(e, newValue) => {
                            setFormData(
                                {
                                    ...formData,
                                    product: newValue,
                                    quantity: 0,
                                    item: null
                                }
                            )
                        }}
                    />
                    <Autocomplete
                        disabled={formData.product === null}
                        id="generate-ser-credit-form-item-select"
                        options={getAvailableItemsByProductId(formData.product?.id)}
                        getOptionLabel={(item) => `ID: ${item.id} | ${item.quantity} L`}
                        renderInput={(params) => <TextField {...params} label="Item"/>}
                        value={formData.item}
                        onChange={(e, newValue) => {
                            setFormData(
                                {
                                    ...formData,
                                    item: newValue,
                                    quantity: newValue === null ? 0 : newValue.quantity,
                                }
                            )
                        }}
                    />
                    <TextField
                        label="Item Quantity (L)"
                        type="number"
                        value={formData.quantity}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                quantity: Number(e.target.value)
                            })
                        }}
                        InputProps={{
                            inputProps: {
                                max: formData.item?.quantity,
                                min: 0
                            }
                        }} // Ensures a minimum value of 0
                        variant="outlined"
                    />
                    <Autocomplete
                        // disabled={formData.standard === null}
                        id="generate-ser-credit-form-standard-select"
                        options={Object.values(CreditStandard)}
                        getOptionLabel={(standard) => `${standard} | Emission Factor: ${getStandardCarbonIntensityCombustion(standard)} CO2e/ MJ`}
                        renderInput={(params) => <TextField {...params} label="Standard"/>}
                        value={formData.standard}
                        onChange={(e, newValue) => {
                            setFormData(
                                {
                                    ...formData,
                                    standard: newValue,
                                }
                            )
                        }}
                    />
                    {formData.product && formData.item && formData.standard &&
                        (formData.quantity > formData.item.quantity ?
                            <Typography
                                variant="caption"
                                color="error"
                                mt={1}
                                mb={1}
                                sx={{textAlign: 'right'}}
                            >
                                Insufficient Quantity
                            </Typography> :
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                mt={1}
                                mb={1}
                                sx={{textAlign: 'right'}}
                            >
                                = {calculateCreditsGenerated(formData.product.carbonIntensityCombustion, formData.product.density, formData.product.energyContent, formData.quantity, formData.standard).toLocaleString()} SAF
                                Credits
                            </Typography>)
                    }

                </FormControl>
                <DialogActions>
                    <Button onClick={closeGenerateCreditDialog} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        disabled={!formData.product || !formData.item || !formData.standard || formData.quantity < 1 || formData.quantity > formData.item.quantity}
                        onClick={() => {
                            generateCredit()
                        }}
                        color="primary"
                        variant="outlined"
                        // disabled
                    >
                        Generate
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>)
}

export default GenerateCreditForm;