import {atom} from "jotai";

export enum ProductType {
    USED_COOKING_OIL = "Used Cooking Oil",
    WASTE_OILS = "Waste Oils",
    ALGAE = "Algae",
    SUGARCANE = "Sugarcane",
    CORN = "Corn",
    PLANT_OILS = "Plant Oils",
}

export interface Product {
    id: number,
    code: string,
    name: string,
    feedstockType: ProductType,
    energyContent: number, // MJ/kg
    certification: string,
    sourceLocation: string[],
    facility: string,
    facilityCountry: string,
    technology: string,
    density: number, // kg/m3
    carbonIntensityLifecycle: number, // gCO2e/MJ
    carbonIntensityCombustion: number // gCO2e/MJ
}


export const selectedProductTypeAtom = atom<ProductType | '*'>('*')
export const products: Product[] = [
    {
        id: 1,
        code: "UCO001",
        name: "UCO Biodiesel",
        feedstockType: ProductType.USED_COOKING_OIL,
        energyContent: 34,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 772,
        carbonIntensityLifecycle: 6.92,
        carbonIntensityCombustion: 5.29
    },
    // {
    //     id: 2,
    //     code: "SUG004",
    //     name: "Sugarcane Extract",
    //     feedstockType: ProductType.SUGARCANE,
    //     energyContent: 36,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 824,
    //     carbonIntensityLifecycle: 22,
    //     carbonIntensityCombustion: 18
    // },
    {
        id: 2,
        code: "WOIL002",
        name: "Waste Oils",
        feedstockType: ProductType.WASTE_OILS,
        energyContent: 37,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 816,
        carbonIntensityLifecycle: 21,
        carbonIntensityCombustion: 11
    },
    {
        id: 3,
        code: "ALG003",
        name: "Algae-based Oil",
        feedstockType: ProductType.ALGAE,
        energyContent: 38,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 833,
        carbonIntensityLifecycle: 23,
        carbonIntensityCombustion: 17
    },
    {
        id: 4,
        code: "SUG004",
        name: "Sugarcane Extract",
        feedstockType: ProductType.SUGARCANE,
        energyContent: 36,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 824,
        carbonIntensityLifecycle: 22,
        carbonIntensityCombustion: 18
    },
    {
        id: 5,
        code: "CORN005",
        name: "Corn-based Oil",
        feedstockType: ProductType.CORN,
        energyContent: 34,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 813,
        carbonIntensityLifecycle: 19,
        carbonIntensityCombustion: 11
    },
    {
        id: 6,
        code: "PLANT006",
        name: "Plant-derived Oils",
        feedstockType: ProductType.PLANT_OILS,
        energyContent: 38,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 790,
        carbonIntensityLifecycle: 25,
        carbonIntensityCombustion: 16
    },
    {
        id: 7,
        code: "UCO007",
        name: "Refined Used Cooking Oil",
        feedstockType: ProductType.USED_COOKING_OIL,
        energyContent: 34,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 772,
        carbonIntensityLifecycle: 6.92,
        carbonIntensityCombustion: 5.29
    },
    {
        id: 8,
        code: "WOIL008",
        name: "Waste Oils Blend",
        feedstockType: ProductType.WASTE_OILS,
        energyContent: 30,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 816,
        carbonIntensityLifecycle: 33,
        carbonIntensityCombustion: 6
    },
    {
        id: 9,
        code: "ALG009",
        name: "Algae Lipids",
        feedstockType: ProductType.ALGAE,
        energyContent: 36,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 794,
        carbonIntensityLifecycle: 38,
        carbonIntensityCombustion: 14
    },
    {
        id: 10,
        code: "CORN010",
        name: "Ethanol from Corn",
        feedstockType: ProductType.CORN,
        energyContent: 36,
        certification: "ISCC-EU",
        sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
        facility: "Neste",
        facilityCountry: "Finland",
        technology: "HEFA",
        density: 798,
        carbonIntensityLifecycle: 20,
        carbonIntensityCombustion: 17
    },
    // {
    //     id: 11,
    //     code: "SUG011",
    //     name: "Sugarcane-based Ethanol",
    //     feedstockType: ProductType.SUGARCANE,
    //     energyContent: 33,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 799,
    //     carbonIntensityLifecycle: 13,
    //     carbonIntensityCombustion: 6
    // },
    // {
    //     id: 12,
    //     code: "PLANT012",
    //     name: "Plant-derived Biofuel",
    //     feedstockType: ProductType.PLANT_OILS,
    //     energyContent: 39,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 805,
    //     carbonIntensityLifecycle: 6,
    //     carbonIntensityCombustion: 5
    // },
    // {
    //     id: 13,
    //     code: "UCO013",
    //     name: "UCO for Biodiesel",
    //     feedstockType: ProductType.USED_COOKING_OIL,
    //     energyContent: 34,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 772,
    //     carbonIntensityLifecycle: 6.92,
    //     carbonIntensityCombustion: 5.29
    // },
    // {
    //     id: 14,
    //     code: "WOIL014",
    //     name: "Waste Oils Conversion",
    //     feedstockType: ProductType.WASTE_OILS,
    //     energyContent: 30,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 803,
    //     carbonIntensityLifecycle: 33,
    //     carbonIntensityCombustion: 11
    // },
    // {
    //     id: 15,
    //     code: "ALG015",
    //     name: "Algal Biofuel",
    //     feedstockType: ProductType.ALGAE,
    //     energyContent: 33,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 819,
    //     carbonIntensityLifecycle: 17,
    //     carbonIntensityCombustion: 16
    // },
    // {
    //     id: 16,
    //     code: "CORN016",
    //     name: "Corn-based Fuel",
    //     feedstockType: ProductType.CORN,
    //     energyContent: 34,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 792,
    //     carbonIntensityLifecycle: 17,
    //     carbonIntensityCombustion: 5
    // },
    // {
    //     id: 17,
    //     code: "SUG017",
    //     name: "Sugarcane-derived Bioethanol",
    //     feedstockType: ProductType.SUGARCANE,
    //     energyContent: 35,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 797,
    //     carbonIntensityLifecycle: 23,
    //     carbonIntensityCombustion: 15
    // },
    // {
    //     id: 18,
    //     code: "PLANT018",
    //     name: "Plant Oils Blend",
    //     feedstockType: ProductType.PLANT_OILS,
    //     energyContent: 38,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 778,
    //     carbonIntensityLifecycle: 36,
    //     carbonIntensityCombustion: 11
    // },
    // {
    //     id: 19,
    //     code: "UCO019",
    //     name: "Recycled UCO Blend",
    //     feedstockType: ProductType.USED_COOKING_OIL,
    //     energyContent: 34,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 772,
    //     carbonIntensityLifecycle: 6.92,
    //     carbonIntensityCombustion: 5.29
    // },
    // {
    //     id: 20,
    //     code: "WOIL020",
    //     name: "Industrial Waste Repurposed",
    //     feedstockType: ProductType.WASTE_OILS,
    //     energyContent: 30,
    //     certification: "ISCC-EU",
    //     sourceLocation: ["China", "Saudi Arabia", "Malaysia", "United Arab Emirates", "United Kingdom", "Spain", "Israel", "Ecuador", "Peru", "Netherlands", "Ireland", "France", "Vietnam", "Jordan", "United States"],
    //     facility: "Neste",
    //     facilityCountry: "Finland",
    //     technology: "HEFA",
    //     density: 837,
    //     carbonIntensityLifecycle: 18,
    //     carbonIntensityCombustion: 7
    // }
]


export const productsAtom = atom<Product[]>(products)

// function getRandomInt(min: number, max: number) {
//     return Math.floor(Math.random() * (max - min)) + min;
// }
//
// function addField(products: Product[]) {
//
//     return products.map(product => {
//         Math.random()
//         const {
//             // origin, refinery, emissionFactor,
//             ...rest
//         } = product
//         return {
//             ...rest,
//             energyContent: getRandomInt(30, 40),
//             density: getRandomInt(775, 840),
//             carbonIntensityLifecycle: getRandomInt(5, 40),
//             carbonIntensityCombustion: getRandomInt(5, 20),
//         }
//     });
// }
//
// function printProducts(products: any) {
//     const productsString = products.map((product: any) => {
//         const {feedstockType} = product;
//         const feedstockTypeKey = Object.keys(ProductType).find(key => ProductType[key as keyof typeof ProductType] === feedstockType);
//         let str = "{"
//         str += Object.keys(product).map((key) => {
//             if (key === "feedstockType") {
//                 return ` ${key}: ProductType.${feedstockTypeKey} `
//             }
//             return ` ${key}: ${JSON.stringify(product[key])} `
//         }).join(',\n')
//         str += "}"
//         return str
//     }).join(',\n');
//
//     console.log(`[\n${productsString}\n]`);
// }
//
// const updatedProducts = addField(products);
// printProducts(updatedProducts);
