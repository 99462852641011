import {DataGrid, GridColDef, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {utcToLocalFormat} from "../../utility/utcToLocalFormat";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Link, Paper,
    Popper, Table, TableBody, TableCell,
    TableContainer, TableRow,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {SerCredit, serCreditsAtom, SerCreditState} from "../../atom/SerCreditAtom";
import {SafCredit, safCreditsAtom, SafCreditState} from "../../atom/SafCreditAtom";
import {useAtom} from "jotai/index";
import {Client, clientsAtom} from "../../atom/ClientAtom";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {Item, itemsAtom} from "../../atom/ItemAtom";
import {useNavigate} from "react-router-dom";
import {customFormatDate, formatNumberToPrice, getEmissionAbatedDetails} from "../../utility/utils";
import {getInventoryHolding} from "../inventoryManagement/utils/InventoryUtils";

interface SerCreditTableInt {
    filterClient?: Client,
    withClient?: boolean,
    withUnlockButton?: boolean,
    filterState?: SerCreditState,
    ariaLabelPrefix?: string
    setTabValue?: React.Dispatch<React.SetStateAction<SerCreditState>>
}

const SerCreditTable = (params: SerCreditTableInt) => {
    const {filterClient, filterState, ariaLabelPrefix, withClient = false, setTabValue} = params
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });
    const [safCredits] = useAtom(safCreditsAtom)
    const [serCredits, setSerCredits] = useAtom(serCreditsAtom)
    const [selectedUnlockCredit, setSelectedUnlockCredit] = useState<SerCredit | null>(null)
    const [loading, setLoading] = useState<boolean>()
    const [unlockDialogActive, setUnlockDialogActive] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const getSafCreditFromSerCredit = (credit: SerCredit): SafCredit | null => {

        return safCredits.find(safCredit => safCredit.id === credit.safCreditId) ?? null
    }

    const getRows = (): SerCredit[] => {
        return serCredits.filter(serCredit => {
            if (!filterClient) {
                return true
            }
            return serCredit.clientId === filterClient.id
        }).filter(serCredit => {
            if (!filterState) {
                return true
            }
            return serCredit.state === filterState
        })
    }
    const [items] = useAtom(itemsAtom)
    const [products] = useAtom(productsAtom)
    const [clients] = useAtom(clientsAtom)
    const getClientFromSerCredit = (credit: SerCredit): Client | null => {
        if (!credit.clientId) {
            return null
        }
        return clients.find(client => client.id === credit.clientId) ?? null
    }
    const getProductFromSerCredit = (credit: SerCredit): Product | null => {
        const safCredit = safCredits.find((safCredit => safCredit.id === credit.safCreditId))
        const item = items.find(item => item.id === safCredit?.itemId)
        const product = products.find(product => product.id === item?.productId)
        return product ?? null
    }

    const navigate = useNavigate()

    const appendClientIdIfHeld = (arr: GridColDef<SerCredit, any, any>[]) => {
        if (filterState === SerCreditState.HELD && withClient) {
            let copy = [...arr]
            copy.push({
                field: 'clientId',
                headerName: "Client",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    return (<>{getClientFromSerCredit(params.row)?.name}</>)
                }
            },)
            return copy
        }
        return arr
    }

    const appendRetiredDateAndCertificateIfRetired = (arr: GridColDef<SerCredit, any, any>[]) => {
        if (filterState === SerCreditState.RETIRED) {
            let copy = [...arr]
            copy = copy.concat([
                {
                    field: 'retiredDate',
                    headerName: "Retired Date",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {
                        if (!params.row.retiredDate) {
                            return <></>
                        }
                        return (<>{customFormatDate(params.row.retiredDate)}</>)
                    }
                },
                {
                    field: 'open pdf',
                    headerName: " ",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {

                        return (<Button onClick={() => {
                            window.open("/SAF_End-User_Reduction_Certificate.pdf")
                        }}>Certificate</Button>)
                    },
                },
            ],)
            return copy
        }
        return arr
    }

    const appendUnlockIfLockedToMarket = (arr: GridColDef<SerCredit, any, any>[]) => {
        if (filterState === SerCreditState.LOCKED_TO_MARKET && params.withUnlockButton) {
            let copy = [...arr]
            copy = copy.concat([
                {
                    field: 'unlock',
                    headerName: " ",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {
                        return (<Button variant={"outlined"} onClick={() => {
                            setSelectedUnlockCredit(params.row)
                            setUnlockDialogActive(true)
                        }}>unlock</Button>)
                    },
                }
            ])
            return copy
        }
        return arr
    }

    const getItemFromSerCredit = (credit : SerCredit) : Item | null => {
        const safCredit = safCredits.find((safCredit => safCredit.id === credit.safCreditId))
        return  items.find(item => item.id === safCredit?.itemId) ?? null
    }

    const getColumns = (): GridColDef<SerCredit, any, any>[] => {
        let columns: GridColDef<SerCredit, any, any>[] = [
            {
                field: 'id',
                headerName: "ID",
            },
            {
                field: 'creditType',
                headerName: "Credit Type",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    return (<>SAF End-User Reduction</>)
                }
            },
            {
                field: 'feedstockType',
                headerName: "Feedstock Type",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    const tableRowStyle = {'&:last-child td, &:last-child th': {border: 0}};

                    const product = getProductFromSerCredit(params.row)!!
                    const item = getItemFromSerCredit(params.row)!!
                    const safCredit = getSafCreditFromSerCredit(params.row)!!
                    return (<>

                        <Button
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                setAnchorEl(anchorEl === event.currentTarget ? null : event.currentTarget);
                            }}
                        >
                            {getProductFromSerCredit(params.row)?.feedstockType}
                        </Button>
                        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={"right-start"}
                                sx={{maxWidth: "30%", maxHeight: "60%", overflowY: "scroll"}}>
                            <TableContainer component={Paper} sx={{padding: 1}}>
                                <Typography variant="h2">Sustainable Aviation Fuel Details</Typography>
                                <Table sx={{}} size='small' aria-label="product details table">
                                    <TableBody>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Name
                                            </TableCell>
                                            <TableCell>{product.name}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Code
                                            </TableCell>
                                            <TableCell>{product.code}</TableCell>
                                        </TableRow>

                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Feedstock Type
                                            </TableCell>
                                            <TableCell>{product.feedstockType}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Source Location
                                            </TableCell>
                                            <TableCell>{product.sourceLocation.join(", ")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Certification
                                            </TableCell>
                                            <TableCell>{product.certification}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Facility
                                            </TableCell>
                                            <TableCell>{product.facility} ({product.facilityCountry})</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Technology
                                            </TableCell>
                                            <TableCell>{product.technology}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Inventory Holdings
                                            </TableCell>
                                            <TableCell>{getInventoryHolding(product.id, items)}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Energy Content (MJ/ kg)
                                            </TableCell>
                                            <TableCell>{product.energyContent}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Density (kg/ m3)
                                            </TableCell>
                                            <TableCell>{product.density}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Combustion Carbon Intensity (gCO2e/ MJ)
                                            </TableCell>
                                            <TableCell>{product.carbonIntensityCombustion}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Lifecycle Carbon Intensity (gCO2e/ MJ)
                                            </TableCell>
                                            <TableCell>{product.carbonIntensityLifecycle}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Lifecycle CO2e Reduction
                                            </TableCell>
                                            <TableCell>{getEmissionAbatedDetails(product, item.quantity, safCredit.standard).emissionsReductionLifecycle.toFixed(2)}%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Popper>
                    </>)
                }
            },

            {
                field: 'location',
                headerName: "Location",
            },
            {
                field: 'quantity',
                headerName: "Quantity",
            },
            {
                field: 'standard',
                headerName: "Standard",
                renderCell: (params) => {
                    return (<>{getSafCreditFromSerCredit(params.row)?.standard}</>)
                }
            },
            {
                field: 'createdAt',
                headerName: "Created At",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    const date = params.value as Date
                    return (<>{customFormatDate(date)}</>)
                }
            },
        ]
        return appendUnlockIfLockedToMarket(appendRetiredDateAndCertificateIfRetired(appendClientIdIfHeld(columns)))
    }

    return (
        <Box style={{width: '100%', height: 'auto', overflow: 'auto', marginTop: "10px"}}>
            <DataGrid
                autoHeight={true}
                getRowHeight={() => 'auto'}
                sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                        py: '22px'
                    }
                }}
                slots={{toolbar: GridToolbar}}
                rows={getRows()}
                getRowId={(row) => row.id}
                disableRowSelectionOnClick
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                loading={loading}
                columns={getColumns()}
                density="compact"
                disableDensitySelector
                aria-label={`${ariaLabelPrefix} credits table`}
            />
            {unlockDialogActive &&
                <Dialog open={unlockDialogActive}
                        onClose={() => setUnlockDialogActive(false)}>

                    <DialogContent>
                        <Typography variant='h2'>
                            Unlock?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUnlockDialogActive(false)} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                const updatedSerCredit = [...serCredits]
                                updatedSerCredit[serCredits.findIndex(credit => credit.id === selectedUnlockCredit!!.id)] =
                                    {
                                        ...selectedUnlockCredit!!,
                                        state: SerCreditState.AVAILABLE
                                    }
                                setSerCredits(updatedSerCredit)
                                if (setTabValue) {
                                    setTabValue(SerCreditState.AVAILABLE)
                                }
                                setUnlockDialogActive(false)
                                setSelectedUnlockCredit(null)
                            }}
                            color="primary"
                            variant="outlined"
                        >
                            Unlock
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Box>
    )
}

export default SerCreditTable