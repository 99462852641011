import './App.css';
import {
    CssBaseline
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {useAppConfigState} from './state/AppConfig';
import {AuthProvider} from './state/AuthProvider';
import AppContainer from './component/AppContainer';
import {useAtom} from "jotai";
import {settingsAtom, useAppMode} from "./atom/SettingsAtom";

function App() {

    const appConfigState = useAppConfigState();
    const [settings, setSettings] = useAtom(settingsAtom)

    useAppMode()

    return (
        <ThemeProvider theme={settings.theme.theme}>
            <CssBaseline/>
            {appConfigState.settingsLoaded() && (
                <AuthProvider appConfig={appConfigState.getAuthConfig()}>
                    <AppContainer/>
                </AuthProvider>
            )}
        </ThemeProvider>
    );
}

export default App;
