import {clientsAtom, selectedClientAtom} from "../../atom/ClientAtom";
import {useAtom} from "jotai";
import {
    Autocomplete,
    Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {sectionSpace} from "../../Themes";
import React, {useState} from "react";
import {getSafCredit, SerCredit, serCreditsAtom, SerCreditState} from "../../atom/SerCreditAtom";
import {mapEnumToZeroValues} from "../../utility/utils";
import {DataGrid, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {Flight, flightsAtom} from "../../atom/FlightAtom";
import {useLayoutState} from "../../state/Layout";
import {utcToLocalFormat} from "../../utility/utcToLocalFormat";
import {CreditStandard, SafCredit, safCreditsAtom} from "../../atom/SafCreditAtom";
import SerCreditTable from "../serManagement/SerCreditTable";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {itemsAtom} from "../../atom/ItemAtom";
import ClientMain from "../../component/ClientMain";

const ClientManagement = () => {
    const [clients, _setClients] = useAtom(clientsAtom)
    const [selectedClient, setSelectedClient] = useAtom(selectedClientAtom)

    const handleClientChange = (e: any) => {
        const selectedClientId = Number(e.target.value); // Ensure it's a number

        // Find the clientManagement object with the matching ID
        const selectedClient = clients.find((client) => client.id === selectedClientId);

        if (selectedClient) {
            setSelectedClient(selectedClient);
        } else {
            setSelectedClient(null); // No clientManagement found for the selected ID
        }
    };

    return (
        <>
            <Typography variant='h2'>Client Management</Typography>
            <Box sx={{marginBottom: sectionSpace}}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={4}
                    sx={{marginBottom: '0px'}}
                    style={{marginBottom: '20px'}}
                >
                    <Typography>Client</Typography>
                    <Select
                        size="small"
                        id="clientId"
                        name="clientId"
                        role="select"
                        value={selectedClient?.id}
                        onChange={handleClientChange}
                        sx={{width: '10em'}} // Arbitrarily set based on the length of the clientManagement names
                    >
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </Box>
            <ClientMain selectedClient={selectedClient} withCreditSummary={true}/>
        </>
    )
}

export default ClientManagement