import { useEffect } from 'react';
import { useAuth } from '../state/AuthProvider'
import { useNavigate } from "react-router-dom";

function LogoutHandler() {
  const { userManager, setUser, setAccountId } = useAuth();
  const navigate= useNavigate()

  useEffect(() => {
    const signOut = async () => {
      // In most cases userManager will be undefined already. However, in the off chance that it is not, clear out any remaining details.
      if (userManager) {
        localStorage.setItem("currentKeyId", "");

        if (userManager?._settings?._metadata?.end_session_endpoint) {
          try {
            setUser(null);
          } catch (error) {
            console.error('Error during sign out:', error);
          }
        } else {
          try {
            await userManager.removeUser();
            setUser(null);
          } catch (error) {
            console.error('Error during sign out:', error);
          }
        }
        setAccountId('');
      }
    };

    signOut();
    navigate('/app')
  }, [userManager, setUser, navigate]);

  // Render nothing
  return null;
}

export default LogoutHandler;
