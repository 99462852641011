import {atom, useAtom} from "jotai/index";
import {appThemes, CustomTheme} from "../Themes";
import {useLocation} from "react-router-dom";
import {APP_ROUTES, clientHomepage} from "../utility/AppRoutes";
import {useEffect} from "react";

export enum AppMode {
    ADMIN, CLIENT
}

interface Settings {
    mode: AppMode,
    theme: CustomTheme
}

export const clientSettings: Settings = {
    mode: AppMode.CLIENT,
    theme: appThemes["trovio-dark"]
}

export const adminSettings: Settings = {
    mode: AppMode.ADMIN,
    theme: appThemes["trovio-dark"]
}


export const settingsAtom = atom(adminSettings)

export const useAppMode = () => {
    const [settings, setSettings] = useAtom(settingsAtom)

    const location = useLocation();
    const routes = APP_ROUTES
    useEffect(() => {

        const isClientRoute = location.pathname.includes(`${clientHomepage}/`)
        setSettings(isClientRoute ? clientSettings : adminSettings);
    }, [location.pathname, setSettings]);
}
