import {faBars, faGear} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ArrowDropDown} from '@mui/icons-material';
import {Box, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar, Tooltip, Typography} from '@mui/material';
import {SyntheticEvent, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {AppBar, navBarHeight, useLayoutState} from '../state/Layout';
import {APP_ROUTES} from '../utility/AppRoutes';
import {useAtom} from "jotai";
import {AppMode, settingsAtom} from "../atom/SettingsAtom";

interface NavItemInfo {
    label: string;
    pathName: string;
    hasSubMenu: boolean;
    isEnabled?: boolean;
}

interface TabPathNameType {
    [key: string]: NavItemInfo;
}

const routes = APP_ROUTES;

function getPathKeyFromIndex(path: string) {
    return path?.split('/')?.[3];
}

const Navigation = () => {
    const layoutState = useLayoutState();
    // const [_, setAnchorEl] = useState<null | HTMLElement>(null);
    const [tabValue, setTabValue] = useState<string>(
        getPathKeyFromIndex(routes.admin.inventoryManagement.path)
    );
    const [settingsAnchorEl, setSettingsAnchorEl] = useState<any | undefined>()
    const [settings, setSettings] = useAtom(settingsAtom)
    const location = useLocation();
    const pathname = location.pathname;

    const tabPathNames: TabPathNameType = {
        inventoryManagementPage: {
            label: routes.admin.inventoryManagement.label,
            pathName: routes.admin.inventoryManagement.path,
            hasSubMenu: false,
            isEnabled: settings.mode === AppMode.ADMIN
        },
        safManagementPage: {
            label: routes.admin.safManagement.label,
            pathName: routes.admin.safManagement.path,
            hasSubMenu: false,
            isEnabled: settings.mode === AppMode.ADMIN
        },
        serManagementPage: {
            label: routes.admin.serManagement.label,
            pathName: routes.admin.serManagement.path,
            hasSubMenu: false,
            isEnabled: settings.mode === AppMode.ADMIN
        },
        clientManagementPage: {
            label: routes.admin.clientManagement.label,
            pathName: routes.admin.clientManagement.path,
            hasSubMenu: false,
            isEnabled: settings.mode === AppMode.ADMIN
        },
        clientHomePage: {
            label: routes.client.home.label,
            pathName: routes.client.home.path,
            hasSubMenu: false,
            isEnabled: settings.mode === AppMode.CLIENT
        },
        clientMarketplacePage: {
            label: routes.client.marketplace.label,
            pathName: routes.client.marketplace.path,
            hasSubMenu: false,
            isEnabled: settings.mode === AppMode.CLIENT
        }
    };

    // nav item text highlighting
    const navTextColor = {
        color: settings.theme.customProps.darkHeader
            ? settings.theme.theme.palette.primary.contrastText
            : settings.theme.theme.palette.secondary.main
    };

    // Side effects to set active Tab value based on current pathname
    useEffect(() => {
        const pathKey = getPathKeyFromIndex(pathname);
        if (pathKey) {
            // Set Active tab based on current pathname
            setTabValue(pathKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    // nav menu item change event
    const handleTabChange = (event: SyntheticEvent, newPathname: string) => {
        // Don't update tab value if the menu is new Trade dropdown.
        /*if ([getPathKeyFromIndex(tabPathNames.newTrade.pathName)].includes(newPathname)) {
            return;
        }*/
        setTabValue(newPathname);
    };

    // const handleMenuOpen = (event: MouseEvent<any>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    //
    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    // };

    // Event to handle New Trade dropdown
    /*const handleNewTradeMenuClick = () => {
        // Set Tab value as parent pathname so that Parent menu will set to active
        setTabValue(getPathKeyFromIndex(tabPathNames.newTrade.pathName));
        setAnchorEl(null);
    };*/

    const handleModeToggle = () => {
        if (settings.mode === AppMode.ADMIN) {
            navigate(routes.client.home.path)
        } else {
            navigate(routes.admin.inventoryManagement.path)
        }
    }


    const navigate = useNavigate()
    console.log(tabValue)
    return (
        <AppBar position="fixed" open={layoutState.open}>
            <Toolbar variant="dense" sx={{height: navBarHeight}}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={layoutState.handleDrawerOpen}
                    sx={{mr: 2, ...(layoutState.open && {display: 'none'})}}
                >
                    <FontAwesomeIcon icon={faBars}/>
                </IconButton>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography component="div" sx={{...navTextColor, mr: 7, fontWeight: 'bold'}}>
                        Book and Claim
                        {settings.mode === AppMode.ADMIN && <span
                            style={{color: settings.theme.theme.palette.primary.dark, marginLeft: "15px"}}>Admin</span>}
                    </Typography>

                    {/* Tabs Menus */}
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        {Object.keys(tabPathNames).map((key, index) => {
                            const currentTab = tabPathNames[key];
                            // Return null if the tab is disabled via config
                            if (!currentTab?.isEnabled) {
                                return null;
                            }

                            // Return Dropdown if the menu has submenu
                            if (currentTab?.hasSubMenu) {
                                return (
                                    <Tab
                                        key={index}
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    flexWrap: 'nowrap'
                                                }}
                                            >
                                                {currentTab?.label}
                                                {!!currentTab?.hasSubMenu && (
                                                    <ArrowDropDown/>
                                                )}
                                            </Box>
                                        }
                                        sx={{
                                            ...navTextColor,
                                            transition: settings.theme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor:
                                                settings.theme.customProps.navHoverBackgroundColor
                                            }
                                        }}
                                        // onClick={handleMenuOpen}
                                        value={getPathKeyFromIndex(currentTab?.pathName)}
                                    />
                                );
                            }

                            // Return Rest of the menu lists
                            return (
                                <Tab
                                    key={index}
                                    label={currentTab?.label}
                                    component={Link}
                                    to={currentTab?.pathName}
                                    sx={{
                                        ...navTextColor,
                                        transition: settings.theme.customProps.navHoverTransition,
                                        '&:hover': {
                                            backgroundColor:
                                            settings.theme.customProps.navHoverBackgroundColor
                                        },
                                        textTransform: "none"
                                    }}
                                    value={getPathKeyFromIndex(currentTab?.pathName)}
                                />
                            );
                        })}
                    </Tabs>
                    <Box sx={{
                        marginLeft: 'auto', display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        <Typography sx={{
                            marginLeft: 'auto',
                            mr: 2
                        }}>
                            Powered by <strong>CorTen<span
                            style={{color: settings.theme.theme.palette.primary.dark}}>X</span></strong>
                        </Typography>
                        <IconButton
                            onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
                            color="primary"
                        >
                            <Tooltip title="Settings">
                                <FontAwesomeIcon icon={faGear}/>
                            </Tooltip>
                        </IconButton>
                        <Menu
                            open={Boolean(settingsAnchorEl)}
                            anchorEl={settingsAnchorEl}
                            onClose={() => setSettingsAnchorEl(undefined)}
                        >
                            <MenuItem onClick={handleModeToggle}>
                                {settings.mode === AppMode.ADMIN ? 'Switch to Client' : 'Switch to Admin'}
                            </MenuItem>
                        </Menu>
                    </Box>

                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
