/**
 * This file is the app UI entry point
 */
import {PropsWithChildren, useEffect} from 'react';
import {Box} from '@mui/material';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../state/AuthProvider';
import {LayoutProvider} from '../state/Layout';
import {APP_ROUTES, adminHomepage, homepage} from '../utility/AppRoutes';
import MainView from '../view/viewContainer/MainView';
import LoginRedirectHandler from './LoginRedirectHandler';
import LogoutHandler from './LogoutHandler';
import Navigation from './Navigation';
import {NotFound} from './NotFound';
import {useAppConfigState} from '../state/AppConfig';

const routes = APP_ROUTES;

const AppContainer = () => {
    const appConfigState = useAppConfigState();
    return (
        <>
            <Routes>
                {/* Public Routes */}
                <Route
                    path="/"
                    element={<AuthRedirect redirect={routes.client.home.path}/>}
                />
                <Route path={appConfigState.getAuthConfig()['postLogoutRedirectUri']} element={<LogoutHandler/>}/>
                <Route path={appConfigState.getAuthConfig()['redirectUri']} element={<LoginRedirectHandler/>}/>
                <Route path="*" element={<NotFound/>}/>

                {/* Protected App Routes, Only accessible when user is authenticated */}
                <Route
                    path={homepage}
                    element={
                        <RequireAuth>
                            <DashboardWrapper>
                                <Box sx={{flexGrow: 1}}>
                                    <LayoutProvider>
                                        <Navigation/>
                                        <MainView/>
                                    </LayoutProvider>
                                </Box>
                            </DashboardWrapper>
                        </RequireAuth>
                    }
                >
                    <Route
                        index
                        path={`${routes.admin.inventoryManagement.path}`}
                        element={routes.admin.inventoryManagement.component}
                    />
                    <Route
                        index
                        path={routes.admin.inventoryProductDetails.path}
                        element={routes.admin.inventoryProductDetails.component}
                    />
                    <Route
                        index
                        path={`${routes.admin.safManagement.path}`}
                        element={routes.admin.safManagement.component}
                    />
                    <Route
                        index
                        path={`${routes.admin.serManagement.path}`}
                        element={routes.admin.serManagement.component}
                    />
                    <Route
                        index
                        path={`${routes.admin.clientManagement.path}`}
                        element={routes.admin.clientManagement.component}
                    />
                    <Route
                        index
                        path={`${routes.client.home.path}`}
                        element={routes.client.home.component}
                    />
                    <Route
                        index
                        path={`${routes.client.marketplace.path}`}
                        element={routes.client.marketplace.component}
                    />
                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </>
    );
};

export default AppContainer;

// Wrapper component to check if user is authenticated
const RequireAuth = ({children}: PropsWithChildren) => {
    const {user, isTokenExpired} = useAuth();
    if (user && !isTokenExpired(user)) {
        return <>{children}</>;
    }
    return null;
};

// Wrapper component to redirect (Authenticated user) to any page based on redirect props passed
const AuthRedirect = ({redirect}: { redirect: string }) => {
    const {user, isTokenExpired} = useAuth();
    if (user && !isTokenExpired(user)) {
        return <Navigate to={redirect}/>;
    }
    return <></>;
};

// Dashboard Root Wrapper, which redirects the user to the adminHomepage if they are authenticated
const DashboardWrapper = ({children}: PropsWithChildren) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;
    useEffect(() => {
        if (pathname === adminHomepage) {
            navigate(routes.client.home.path);
        }
    }, [navigate, pathname]);

    return <>{children}</>;
};
