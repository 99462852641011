import {useParams} from "react-router-dom";
import {useAtom} from "jotai/index";
import {Product, productsAtom} from "../../atom/ProductAtom";
import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog, DialogActions, DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import Error from '../../component/Error';
import {sectionSpace} from "../../Themes";
import {DataGrid, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {Item, itemsAtom} from "../../atom/ItemAtom";
import {getCreditId, getInventoryHolding} from "./utils/InventoryUtils";
import GenerateCreditForm, {GenerateCreditFormData, getDefaultGenerateCreditFormData} from "./GenerateCreditForm";
import {clientsAtom} from "../../atom/ClientAtom";
import {utcToLocalFormat} from "../../utility/utcToLocalFormat";
import {SafCredit, safCreditsAtom} from "../../atom/SafCreditAtom";
import {getCarbonEmissions} from "../../utility/utils";

const ProductDetails = () => {
    const {productId} = useParams()
    const [products, setProducts] = useAtom(productsAtom);
    const [product, setProduct] = useState(products.find((product) =>
        product.id === Number(productId)
    ))
    const [items, setItems] = useAtom(itemsAtom)
    const productItems = () =>
        product === undefined ? [] :
            items.filter((item) => item.productId == product.id)

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });
    const [loading, setLoading] = useState<boolean>()
    const tableRowStyle = {'&:last-child td, &:last-child th': {border: 0}};
    const [generateCreditDialogActive, setGenerateCreditDialogActive] = useState(false);
    const [creditDialogActive, setCreditDialogActive] = useState(false);
    const [selectedSafCredit, setSelectedSafCredit] = useState<SafCredit | null>(null)
    const openGenerateCreditDialog = () => {
        setGenerateCreditDialogActive(true);
    };

    const closeGenerateCreditDialog = () => {
        setGenerateCreditDialogActive(false);
    };

    const [safCredits] = useAtom(safCreditsAtom)
    const [clients] = useAtom(clientsAtom)

    const mapWithSafCreditId = (): (Item & {
        safCreditId: number | undefined
    })[] => {
        return productItems().map((item) => ({
            ...item,
            safCreditId: getCreditId(item.id, safCredits),
        }));
    };
    const [generateCreditFormData, setGenerateCreditFormData] = useState<GenerateCreditFormData>(getDefaultGenerateCreditFormData(product))
    const openCreditDialog = (creditId: number) => {
        setCreditDialogActive(true)
        setSelectedSafCredit(safCredits.find((safCredit) => safCredit.id === creditId) ?? null)
    };
    const closeCreditDialog = () => {
        setCreditDialogActive(false)
    };
    return (<>
        <Typography variant="h2">
            Product Details
        </Typography>
        {product !== undefined ?
            <>
                <Box sx={{marginBottom: sectionSpace}}>
                    <TableContainer component={Paper} sx={{minWidth: 750}}>
                        <Table sx={{minWidth: 750}} size='small' aria-label="product details table">
                            <TableBody>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        ID
                                    </TableCell>
                                    <TableCell>{product.id}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Name
                                    </TableCell>
                                    <TableCell>{product.name}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Code
                                    </TableCell>
                                    <TableCell>{product.code}</TableCell>
                                </TableRow>

                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Feedstock Type
                                    </TableCell>
                                    <TableCell>{product.feedstockType}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Source Location
                                    </TableCell>
                                    <TableCell>{product.sourceLocation.join(", ")}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Certification
                                    </TableCell>
                                    <TableCell>{product.certification}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Facility
                                    </TableCell>
                                    <TableCell>{product.facility} ({product.facilityCountry})</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Technology
                                    </TableCell>
                                    <TableCell>{product.technology}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Inventory Holdings
                                    </TableCell>
                                    <TableCell>{getInventoryHolding(product.id, items)}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Energy Content (MJ/ kg)
                                    </TableCell>
                                    <TableCell>{product.energyContent}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Density (kg/ m3)
                                    </TableCell>
                                    <TableCell>{product.density}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Combustion Carbon Intensity (gCO2e/ MJ)
                                    </TableCell>
                                    <TableCell>{product.carbonIntensityCombustion}</TableCell>
                                </TableRow>
                                <TableRow hover sx={tableRowStyle}>
                                    <TableCell sx={{width: 350}}>
                                        Lifecycle Carbon Intensity (gCO2e/ MJ)
                                    </TableCell>
                                    <TableCell>{product.carbonIntensityLifecycle}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Typography variant='h3'>
                    Items
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openGenerateCreditDialog}
                >Generate Credit
                </Button>
                {generateCreditDialogActive && (
                    <GenerateCreditForm
                        generateCreditDialogActive={generateCreditDialogActive}
                        closeGenerateCreditDialog={closeGenerateCreditDialog}
                        formData={generateCreditFormData}
                        setFormData={setGenerateCreditFormData}
                        productSelectDisabled={true}
                    />
                )}
                <Box style={{width: '100%', height: 'auto', overflow: 'auto', marginTop: "10px"}}>
                    <DataGrid
                        autoHeight={true}
                        getRowHeight={() => 'auto'}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                py: '22px'
                            }
                        }}
                        slots={{toolbar: GridToolbar}}
                        rows={mapWithSafCreditId()}
                        getRowId={(row) => row.id}
                        disableRowSelectionOnClick
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) => setFilterModel(newModel)}
                        loading={loading}
                        columns={[
                            {
                                field: 'id',
                                headerName: "ID",
                                minWidth: 150,
                                flex: 1
                            },
                            {
                                field: 'createdAt',
                                headerName: "Created Date",
                                minWidth: 150,
                                flex: 1,
                                renderCell: (params) => {
                                    return <>{utcToLocalFormat(params.row.createdAt.toString())}</>
                                }
                            },
                            {
                                field: 'quantity',
                                headerName: "Holdings (L)",
                                minWidth: 150,
                                flex: 1,
                                filterable: false
                            },
                            {
                                field: "carbonEmissionsCombustion",
                                headerName: "Combustion CO2e (tonnes)",
                                minWidth: 150,
                                flex: 1,
                                filterable: false,
                                renderCell: (params) => {
                                    return <>{Math.floor(getCarbonEmissions(product, params.row.quantity).emissionsCombustion)}</>
                                }
                            },
                            {
                                field: "carbonEmissionsLifecycle",
                                headerName: "Lifecycle CO2e (tonnes)",
                                minWidth: 150,
                                flex: 1,
                                filterable: false,
                                renderCell: (params) => {
                                    return <>{Math.floor(getCarbonEmissions(product, params.row.quantity).emissionsLifecycle)}</>
                                }
                            },
                            {
                                field: 'creditId',
                                headerName: "SAFc Generated?",
                                minWidth: 150,
                                flex: 1,
                                filterable: false,
                                renderCell: (params) => {
                                    return <>{params.row.safCreditId ? <Button variant="outlined"
                                                                               onClick={() => openCreditDialog(params.row.safCreditId!!)}>Yes</Button> : ""}</>
                                }
                            },


                        ]}
                        density="compact"
                        disableDensitySelector
                        aria-label="product table"
                    />
                </Box>
                {creditDialogActive &&
                    <Dialog
                        open={creditDialogActive}
                        onClose={closeCreditDialog}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                            <Typography variant='h2'>
                                SAF Credit (ID: {selectedSafCredit?.id})
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    marginBottom: '1rem'
                                }}
                            >
                                <Typography sx={{flex: '0 0 130px'}}>Name</Typography>

                                <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>
                                    {selectedSafCredit?.name}
                                </Typography>
                            </Box>
                            {/*<Box*/}
                            {/*    sx={{*/}
                            {/*        display: 'flex',*/}
                            {/*        justifyContent: 'flex-start',*/}
                            {/*        flexDirection: 'row',*/}
                            {/*        marginBottom: '1rem'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Typography sx={{flex: '0 0 130px'}}>Held By</Typography>*/}

                            {/*    <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>*/}

                            {/*        {clients.find((client) => client.id === selectedCredit?.clientId)?.name ?? "-"}*/}
                            {/*    </Typography>*/}
                            {/*</Box>*/}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    marginBottom: '1rem'
                                }}
                            >
                                <Typography sx={{flex: '0 0 130px'}}>Quantity (Credits)</Typography>

                                <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>

                                    {selectedSafCredit?.quantity}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    marginBottom: '1rem'
                                }}
                            >
                                <Typography sx={{flex: '0 0 130px'}}>Standard</Typography>

                                <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>

                                    {selectedSafCredit?.standard}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    marginBottom: '1rem'
                                }}
                            >
                                <Typography sx={{flex: '0 0 130px'}}>Location</Typography>

                                <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>

                                    {selectedSafCredit?.location}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    marginBottom: '1rem'
                                }}
                            >
                                <Typography sx={{flex: '0 0 130px'}}>State</Typography>

                                <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>

                                    {selectedSafCredit?.state}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    marginBottom: '1rem'
                                }}
                            >
                                <Typography sx={{flex: '0 0 130px'}}>Created Date</Typography>

                                <Typography sx={{flex: '1', wordBreak: 'break-word', textAlign: 'right'}}>

                                    {selectedSafCredit && utcToLocalFormat(selectedSafCredit?.createdAt.toString())}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeCreditDialog} color="primary" variant="outlined">
                                Close
                            </Button>

                        </DialogActions>
                    </Dialog>
                }
            </>
            :
            <Error message='Error - Product ID not recognised'/>
        }
    </>)
}

export default ProductDetails