import {
    Alert,
    Box,
    Button,
    Chip, ClickAwayListener, Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl, IconButton,
    Link, Paper, Popper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, ToggleButton,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {DataGrid, GridColDef, GridFilterModel, GridToolbar} from "@mui/x-data-grid";
import {useAtom} from "jotai";
import {SerCredit, serCreditsAtom, SerCreditState} from "../../atom/SerCreditAtom";
import {SafCredit, safCreditsAtom} from "../../atom/SafCreditAtom";
import {Item, itemsAtom} from "../../atom/ItemAtom";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {useNavigate} from "react-router-dom";
import {customFormatDate, formatNumberToPrice, getEmissionAbatedDetails} from "../../utility/utils";
import {clientsAtom} from "../../atom/ClientAtom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {getInventoryHolding} from "../inventoryManagement/utils/InventoryUtils";

const Marketplace = () => {
    const [serCredits, setSerCredits] = useAtom(serCreditsAtom)
    const [safCredits] = useAtom(safCreditsAtom)
    const [items] = useAtom(itemsAtom)
    const [products] = useAtom(productsAtom)
    const [loading, setLoading] = useState<boolean>()
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });
    const [buyDialogActive, setBuyDialogActive] = useState<boolean>()
    const [clients, setClients] = useAtom(clientsAtom)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    interface BuyFormData {
        serCredit?: SerCredit,
        quantity: number
    }

    const [buyFormData, setBuyFormData] = useState<BuyFormData>({
        quantity: 0
    })
    const getSafCreditFromSerCredit = (credit: SerCredit): SafCredit | null => {

        return safCredits.find(safCredit => safCredit.id === credit.safCreditId) ?? null
    }


    const getProductFromSerCredit = (credit: SerCredit): Product | null => {
        const safCredit = safCredits.find((safCredit => safCredit.id === credit.safCreditId))
        const item = items.find(item => item.id === safCredit?.itemId)
        const product = products.find(product => product.id === item?.productId)
        return product ?? null
    }

    const getItemFromSerCredit = (credit: SerCredit): Item | null => {
        const safCredit = safCredits.find((safCredit => safCredit.id === credit.safCreditId))
        return items.find(item => item.id === safCredit?.itemId) ?? null
    }

    const navigate = useNavigate()

    const openBuyDialog = (serCredit: SerCredit) => {
        setBuyFormData({serCredit, quantity: 1})
        setBuyDialogActive(true)
    }

    const getColumns = (): GridColDef<SerCredit, any, any>[] => {
        let columns: GridColDef<SerCredit, any, any>[] = [
            {
                field: 'id',
                headerName: "ID",
            },
            {
                field: 'creditType',
                headerName: "Credit Type",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    return (<>SAF End-User Reduction</>)
                }
            },
            {
                field: 'feedstockType',
                headerName: "Feedstock Type",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    const tableRowStyle = {'&:last-child td, &:last-child th': {border: 0}};

                    const product = getProductFromSerCredit(params.row)!!
                    const item = getItemFromSerCredit(params.row)!!
                    const safCredit = getSafCreditFromSerCredit(params.row)!!
                    return (<>

                        <Button
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                setAnchorEl(anchorEl === event.currentTarget ? null : event.currentTarget);
                            }}
                        >
                            {getProductFromSerCredit(params.row)?.feedstockType}
                        </Button>
                        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={"right-start"}
                                sx={{maxWidth: "30%", maxHeight: "60%", overflowY: "scroll"}}>
                            <TableContainer component={Paper} sx={{padding: 1}}>
                                <Typography variant="h2">Sustainable Aviation Fuel Details</Typography>
                                <Table sx={{}} size='small' aria-label="product details table">
                                    <TableBody>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Name
                                            </TableCell>
                                            <TableCell>{product.name}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Code
                                            </TableCell>
                                            <TableCell>{product.code}</TableCell>
                                        </TableRow>

                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Feedstock Type
                                            </TableCell>
                                            <TableCell>{product.feedstockType}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Source Location
                                            </TableCell>
                                            <TableCell>{product.sourceLocation.join(", ")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Certification
                                            </TableCell>
                                            <TableCell>{product.certification}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Facility
                                            </TableCell>
                                            <TableCell>{product.facility} ({product.facilityCountry})</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Technology
                                            </TableCell>
                                            <TableCell>{product.technology}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Inventory Holdings
                                            </TableCell>
                                            <TableCell>{getInventoryHolding(product.id, items)}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Energy Content (MJ/ kg)
                                            </TableCell>
                                            <TableCell>{product.energyContent}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Density (kg/ m3)
                                            </TableCell>
                                            <TableCell>{product.density}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Combustion Carbon Intensity (gCO2e/ MJ)
                                            </TableCell>
                                            <TableCell>{product.carbonIntensityCombustion}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Lifecycle Carbon Intensity (gCO2e/ MJ)
                                            </TableCell>
                                            <TableCell>{product.carbonIntensityLifecycle}</TableCell>
                                        </TableRow>
                                        <TableRow sx={tableRowStyle}>
                                            <TableCell sx={{}}>
                                                Lifecycle CO2e Reduction
                                            </TableCell>
                                            <TableCell>{getEmissionAbatedDetails(product, item.quantity, safCredit.standard).emissionsReductionLifecycle.toFixed(2)}%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Popper>
                    </>)
                }
            },

            {
                field: 'location',
                headerName: "Location",
            },
            {
                field: 'quantity',
                headerName: "Quantity",
            },
            {
                field: 'standard',
                headerName: "Standard",
                renderCell: (params) => {
                    return (<>{getSafCreditFromSerCredit(params.row)?.standard}</>)
                }
            },
            {
                field: 'createdAt',
                headerName: "Created At",
                minWidth: 150,
                flex: 1,
                renderCell: (params) => {
                    const date = params.value as Date
                    return (<>{customFormatDate(date)}</>)
                }
            },
            {
                field: 'price',
                headerName: "Price (AUD)",
                renderCell: (params) => {
                    return (<>${formatNumberToPrice(params.row.price || 0)}</>)
                }
            },
            {
                field: 'action',
                headerName: '',
                filterable: false,
                sortable: false,
                disableExport: true,
                renderCell: (params) => {
                    return (
                        <Box>
                            <Button variant="outlined"
                                    onClick={() => openBuyDialog(params.row)}>
                                Buy
                            </Button>
                        </Box>
                    );
                }
            }

        ]
        return columns
    }

    const getRows = (): SerCredit[] => {
        return serCredits.filter(serCredit =>
            serCredit.state === SerCreditState.LOCKED_TO_MARKET
        )
    }

    interface ValidationResult {
        error: boolean,
        message: String
    }

    interface FormValidationResult {
        quantity: ValidationResult
    }

    const validateForm = (): FormValidationResult => {
        let result: any = {
            quantity: {
                error: false,
                message: ""
            }
        }

        if (buyFormData.serCredit && buyFormData.quantity > buyFormData.serCredit.quantity) {
            result.quantity.error = true
            result.quantity.message = `Quantity has to be less than or equal to ${buyFormData.serCredit.quantity}.`
        }

        if (buyFormData.quantity < 1) {
            result.quantity.error = true
            result.quantity.message = "Quantity has to be more than 0."
        }
        return result
    }

    const buyCredit = () => {
        if (buyFormData.quantity === buyFormData.serCredit?.quantity) {
            const updatedSerCredit = [...serCredits]
            updatedSerCredit[serCredits.findIndex(credit => credit.id === buyFormData.serCredit?.id)] =
                {
                    ...buyFormData.serCredit!!,
                    state: SerCreditState.HELD,
                    clientId: clients[0].id
                }
            setSerCredits(updatedSerCredit)
        } else {
            console.log()
            const updatedSerCredit = [...serCredits,

                {
                    ...buyFormData.serCredit!!,
                    state: SerCreditState.HELD,
                    id: serCredits.length + 1,
                    quantity: buyFormData.quantity,
                    clientId: clients[0].id
                }
            ]

            updatedSerCredit[serCredits.findIndex(credit => credit.id === buyFormData.serCredit?.id)] =
                {
                    ...buyFormData.serCredit!!,
                    quantity: buyFormData.serCredit?.quantity!! - buyFormData.quantity
                }
            setSerCredits(updatedSerCredit)
            setOpenSnackbar(true)
        }
        setClients([...clients].map((client, index) => {
            if (index === 0) {
                return {
                    ...client,
                    walletValue: client.walletValue - getTotalPrice()
                }
            }
            return client
        }))
        setBuyDialogActive(false)
    }

    const getTotalPrice = () => {

        if (!buyFormData.serCredit || !buyFormData.serCredit.price) {
            return 0
        }
        return buyFormData.quantity * buyFormData.serCredit.price
    }


    return <>
        <Typography variant="h2">Marketplace</Typography>
        {buyDialogActive && (
            <Dialog
                open={buyDialogActive}
                onClose={() => {
                    setBuyDialogActive(false)
                }}
                fullWidth
                maxWidth="sm">
                <DialogContent>
                    <Box sx={{mb: 2}}>
                        <Typography variant='h2'>
                            Buying SAF End-user Credit
                            <Typography variant='body1'>
                                ID: {buyFormData.serCredit?.id} |
                                Quantity: {buyFormData.serCredit?.quantity}
                            </Typography>
                        </Typography>
                    </Box>

                    <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                        <TextField
                            label="Quantity"
                            type="number"
                            value={buyFormData.quantity}
                            onChange={(e) => {
                                setBuyFormData({
                                    ...buyFormData,
                                    quantity: Number(parseFloat(e.target.value).toFixed(1))
                                })
                            }}
                            InputProps={{
                                inputProps: {
                                    max: buyFormData.serCredit?.quantity,
                                    min: 0
                                }
                            }}
                            variant="outlined"
                            error={validateForm().quantity.error}
                            helperText={validateForm().quantity.message}
                        />
                        {buyFormData.serCredit &&
                            <>
                                {clients[0].walletValue >= getTotalPrice() ? <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    mt={1}
                                    mb={1}
                                    sx={{textAlign: 'right'}}
                                >
                                    Total Price: AUD
                                    ${formatNumberToPrice(getTotalPrice())}
                                </Typography> : <Typography
                                    variant="caption"
                                    color="error"
                                    mt={1}
                                    mb={1}
                                    sx={{textAlign: 'right'}}
                                >
                                    Insufficient Funds. Total Price: ${formatNumberToPrice(getTotalPrice())}. Balance:
                                    ${formatNumberToPrice(clients[0].walletValue)}
                                </Typography>}

                            </>
                        }
                        <DialogActions>
                            <Button onClick={() => setBuyDialogActive(false)} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                disabled={validateForm().quantity.error || clients[0].walletValue < getTotalPrice()}
                                onClick={
                                    buyCredit
                                }
                                color="primary"
                                variant="outlined"
                            >
                                Buy
                            </Button>
                        </DialogActions>
                    </FormControl>
                </DialogContent>
            </Dialog>
        )}

        <DataGrid
            autoHeight={true}
            getRowHeight={() => 'auto'}
            sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '22px'
                }
            }}
            slots={{toolbar: GridToolbar}}
            rows={getRows()}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            filterModel={filterModel}
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            loading={loading}
            columns={getColumns()}
            density="compact"
            disableDensitySelector
            aria-label={`serCredits table`}
        />
        <Box sx={{mb: 5}}></Box>
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} // Position at bottom right
            open={openSnackbar}
            autoHideDuration={2000} // Duration in milliseconds (6 seconds)
            onClose={() => {
                setOpenSnackbar(false)
            }}
        >
            <Alert severity="success" onClose={() => {
                setOpenSnackbar(false)
            }}>
                Credit Successfully Purchased!
            </Alert>
        </Snackbar>
    </>
}

export default Marketplace