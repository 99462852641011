import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {CreditStandard, safCreditsAtom} from "../atom/SafCreditAtom";
import React from "react";
import {getSafCredit, SerCredit} from "../atom/SerCreditAtom";
import {mapEnumToZeroValues} from "../utility/utils";
import {useAtom} from "jotai";

interface ISerCreditSummaryByStandard {
    serCredits: SerCredit[]
}
const SerCreditSummaryByStandard = ({serCredits}: ISerCreditSummaryByStandard) => {
    const [safCredits ] = useAtom(safCreditsAtom)
    const getSummary = () => {
        return serCredits.reduce((summary, serCredit) => {
            const {quantity} = serCredit;
            const {standard} = getSafCredit(serCredit, safCredits)
            summary[standard] += quantity
            return summary;
        }, mapEnumToZeroValues(CreditStandard))
    }
    return(
    <>
        <Typography variant='h3'>
            Credit Summary
        </Typography>
        <TableContainer component={Paper} sx={{width: '520px'}}>
            <Table size='small' aria-label="serManagement summary table">
                <TableHead>
                    <TableRow>
                        {Object.values(CreditStandard).map((standard, index) => (
                            <TableCell key={index} sx={{paddingLeft: 0}} align="right">
                                {standard}
                            </TableCell>
                        ))}

                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        {Object.values(CreditStandard).map((standard, index) => (
                            <TableCell key={index} sx={{paddingLeft: 0}} align="right">
                                {getSummary()[standard]}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}

export default SerCreditSummaryByStandard