/**
 * This file holds all items in the collapsable side menu
 */
import { Box } from '@mui/material';
import { OidcLogin } from '../../component/OidcLogin';

const SideDrawer = () => {
    return (
        <Box
            sx={{
                padding: 3,
                display: 'flex',
                flexGrow: 1
            }}
        >

            <OidcLogin />
        </Box>
    );
};

export default SideDrawer;
