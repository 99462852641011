import React from "react";
import ReactDOM from 'react-dom';
import "./index.css";
import { AppConfigProvider, fetchAppConfig } from './state/AppConfig';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';

(async function fetchConfigData() {
    try {

        let appConfigResponse = await fetchAppConfig();
        if (appConfigResponse.ok) {
            let appConfigJson = await appConfigResponse.json();

            ReactDOM.render(
                <React.StrictMode>
                    <BrowserRouter>
                        <AppConfigProvider appConfig={appConfigJson}>
                            <App />
                        </AppConfigProvider>
                    </BrowserRouter>
                </React.StrictMode>,
                document.getElementById('root')
            );

        } else {
            throw new Error(`${appConfigResponse.status}, ${appConfigResponse.statusText}`);
        }
    } catch (error) {
        console.log('resp not ok');

        console.log('something went wrong', error);
    }
})();
