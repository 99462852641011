import {Box} from "@mui/material";
import {DateRange, DayPicker} from "react-day-picker";
import React, {useState} from "react";
import {isValidDate} from "../utility/utcToLocalFormat";
import dayjs, {Dayjs} from "dayjs";
import {appThemes} from "../Themes";

interface IntDateRangePicker {
    range?: DateRange
    setRange: (newRange: DateRange | undefined) => void
}

const DateRangePicker = ({
                             range,
                             setRange
                         }: IntDateRangePicker) => {
    const hexColor = appThemes['trovio-dark'].theme.palette.primary.main
    const hoverOpacity = appThemes['trovio-dark'].theme.palette.action.hoverOpacity
    const css = `
   .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
        background-color: rgba(${parseInt(hexColor.substring(1, 3), 16)}, ${parseInt(hexColor.substring(3, 5), 16)}, ${parseInt(hexColor.substring(5, 7), 16)}, ${hoverOpacity});
    }
    `
    return (
        <Box sx={{padding: '10px 0px'}}>
            <style>{css}</style>
            <DayPicker
                mode="range"
                defaultMonth={new Date()}
                selected={range}
                footer={null}
                onSelect={setRange}
                modifiersStyles={{
                    selected: {
                        backgroundColor: appThemes['trovio-dark'].theme.palette.primary.main,
                        color: appThemes['trovio-dark'].theme.palette.background.default
                    },
                }}

            />
        </Box>)
}

export default DateRangePicker