import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useAtom} from "jotai";
import {sectionSpace} from "../../Themes";
import {useLayoutState} from "../../state/Layout";
import {GridFilterModel} from "@mui/x-data-grid";
import {mapEnumToZeroValues} from "../../utility/utils";
import {clientsAtom} from "../../atom/ClientAtom";
import {SafCredit, safCreditsAtom, SafCreditState} from "../../atom/SafCreditAtom";
import {itemsAtom} from "../../atom/ItemAtom";
import {Product, productsAtom} from "../../atom/ProductAtom";
import {useNavigate} from "react-router-dom";
import SafCreditTable from "./SafCreditTable";
import {serCreditsAtom, SerCreditState} from "../../atom/SerCreditAtom";

const SafManagement = () => {
    const [safCredits, setSafCredits] = useAtom(safCreditsAtom)
    const [serCredits, setSerCredits] = useAtom(serCreditsAtom)
    const [tabValue, setTabValue] = useState<SafCreditState>(SafCreditState.AVAILABLE);
    const [loading, setLoading] = useState<boolean>()
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: []
    });

    const [clients] = useAtom(clientsAtom)
    const [items] = useAtom(itemsAtom)
    const [products] = useAtom(productsAtom)
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState<boolean>(false);
    const [retireDialogActive, setRetireDialogActive] = useState<boolean>(false);

    interface LockToMarketFormData {
        safCredit: SafCredit | null,
        quantity: number
    }

    const [lockToMarketFormData, setLockToMarketFormData] = useState<LockToMarketFormData>({
        safCredit: null,
        quantity: 0
    })

    interface RetireFormData {
        safCredit: SafCredit | null,
        quantity: number,
        details: string
    }

    const [retireFormData, setRetireFormData] = useState<RetireFormData>({
        safCredit: null,
        quantity: 0,
        details: ""
    })
    const getSummary = (credits: SafCredit[]) => {
        return credits.reduce((summary, credit) => {
            const {state, quantity} = credit;
            summary[state] += quantity
            return summary;
        }, mapEnumToZeroValues(SafCreditState))
    }

    const {customTheme} = useLayoutState();

    const hoverTabTextColor = {color: customTheme.theme.palette.secondary.main};

    const onTabChange = (event: React.SyntheticEvent, value: any): void => {
        setTabValue(value);
    }

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true)
    }

    const closeLockToMarketDialog = () => {
        setLockToMarketDialogActive(false)
    }

    const openRetireDialog = () => {
        setRetireDialogActive(true)
    }

    const closeRetireDialog = () => {
        setRetireDialogActive(false)
    }

    const getProductFromSafCredit = (credit: SafCredit): Product | null => {
        const item = items.find(item => item.id === credit.itemId)
        const product = products.find(product => product.id === item?.productId)
        return product ?? null
    }

    const navigate = useNavigate()

    const lockToMarket = () => {

        if (lockToMarketFormData.quantity === lockToMarketFormData.safCredit?.quantity) {
            const updatedSafCredit = [...safCredits]
            updatedSafCredit[safCredits.findIndex(credit => credit.id === lockToMarketFormData.safCredit?.id)] =
                {
                    ...lockToMarketFormData.safCredit!!,
                    state: SafCreditState.LOCKED_TO_MARKET
                }
            setSafCredits(updatedSafCredit)
        } else {
            const updatedSafCredit = [...safCredits,
                {
                    ...lockToMarketFormData.safCredit!!,

                    quantity: lockToMarketFormData.safCredit?.quantity!! - lockToMarketFormData.quantity
                }
            ]
            updatedSafCredit[safCredits.findIndex(credit => credit.id === lockToMarketFormData.safCredit?.id)] =
                {
                    ...lockToMarketFormData.safCredit!!,
                    state: SafCreditState.LOCKED_TO_MARKET,
                    id: safCredits.length + 1,
                    quantity: lockToMarketFormData.quantity
                }
            setSafCredits(updatedSafCredit)

        }
        setTabValue(SafCreditState.LOCKED_TO_MARKET)
        closeLockToMarketDialog()
        setLockToMarketFormData({
            safCredit: null,
            quantity: 0
        })
    }

    const retire = () => {
        setSerCredits([...serCredits,  {
            id: serCredits.length + 1,
            safCreditId: retireFormData.safCredit?.id!!,
            clientId: null,
            quantity: retireFormData.quantity,
            state: SerCreditState.AVAILABLE,
            createdAt: new Date(),
            retiredDate: null,
            location: "Singapore",
            price: null
        }])
        if (retireFormData.quantity === retireFormData.safCredit?.quantity) {
            const updatedSafCredit = [...safCredits]
            updatedSafCredit[safCredits.findIndex(credit => credit.id === retireFormData.safCredit?.id)] =
                {
                    ...retireFormData.safCredit!!,
                    state: SafCreditState.RETIRED
                }
            setSafCredits(updatedSafCredit)
        } else {
            const updatedSafCredit = [...safCredits,
                {
                    ...retireFormData.safCredit!!,
                    quantity: retireFormData.safCredit?.quantity!! - retireFormData.quantity
                }
            ]
            updatedSafCredit[safCredits.findIndex(credit => credit.id === retireFormData.safCredit?.id)] =
                {
                    ...retireFormData.safCredit!!,
                    state: SafCreditState.RETIRED,
                    id: safCredits.length + 1,
                    quantity: retireFormData.quantity
                }
            setSafCredits(updatedSafCredit)
        }
        closeRetireDialog()
        setRetireFormData({
            safCredit: null,
            quantity: 0,
            details:""
        })
    }

    return (
        <>
            <Typography variant='h2'>Sustainable Aviation Fuel Credit Management</Typography>
            <Box sx={{marginBottom: sectionSpace}}>
                <Typography variant='h3'>
                    Summary
                </Typography>
                <TableContainer component={Paper} sx={{width: '520px'}}>
                    <Table size='small' aria-label="SER Credit summary table">
                        <TableHead>
                            <TableRow>

                                <TableCell key={SafCreditState.AVAILABLE} sx={{paddingLeft: 0}} align="right">
                                    {SafCreditState.AVAILABLE}
                                </TableCell>
                                <TableCell key={SafCreditState.LOCKED_TO_MARKET} sx={{paddingLeft: 0}} align="right">
                                    {SafCreditState.LOCKED_TO_MARKET}
                                </TableCell>
                                <TableCell key={SafCreditState.RETIRED} sx={{paddingLeft: 0}} align="right">
                                    {SafCreditState.RETIRED}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell key={SafCreditState.AVAILABLE} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(safCredits)[SafCreditState.AVAILABLE]}
                                </TableCell>
                                <TableCell key={SafCreditState.LOCKED_TO_MARKET} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(safCredits)[SafCreditState.LOCKED_TO_MARKET]}
                                </TableCell>
                                <TableCell key={SafCreditState.RETIRED} sx={{paddingLeft: 0}} align="right">
                                    {getSummary(safCredits)[SafCreditState.RETIRED]}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Typography variant="h3">
                Available, Held, Locked to Market Credits
            </Typography>
            <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">
                <Button
                    variant="outlined"
                    sx={{width: '10%'}}
                    onClick={openRetireDialog}
                >
                    Retire
                </Button>
                {retireDialogActive &&
                    <Dialog
                        open={retireDialogActive}
                        onClose={closeRetireDialog}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                            <Typography variant='h2'>
                                Retire
                            </Typography>
                            <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                                <Autocomplete
                                    id="lock-to-market-form-credit-select"
                                    options={safCredits.filter((safCredit) => safCredit.state === SafCreditState.AVAILABLE)}
                                    getOptionLabel={(credit) => `${credit.id}: ${getProductFromSafCredit(credit)?.name} | ${credit.quantity} credits`}
                                    renderInput={(params) => <TextField {...params} label="SAF Credit"/>}
                                    value={retireFormData.safCredit}
                                    onChange={(e, newValue) => {
                                        setRetireFormData(
                                            {
                                                ...retireFormData,
                                                safCredit: newValue,
                                                quantity: newValue !== null ? newValue.quantity : 0
                                            }
                                        )
                                    }}
                                />
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={retireFormData.quantity}
                                    onChange={(e) => {
                                        setRetireFormData({
                                            ...retireFormData,
                                            quantity: Number(e.target.value)
                                        })
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: retireFormData.safCredit?.quantity,
                                            min: 0
                                        }
                                    }}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Details"
                                    multiline={true}
                                    value={retireFormData.details}
                                    onChange={(e) => {
                                        setRetireFormData({
                                            ...retireFormData,
                                            details: e.target.value
                                        })
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: retireFormData.safCredit?.quantity,
                                            min: 0
                                        }
                                    }}
                                    variant="outlined"
                                />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    mt={1}
                                    mb={1}
                                    sx={{textAlign: 'right'}}
                                >{retireFormData.quantity} SER Credits will be generated.</Typography>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeRetireDialog} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                disabled={!retireFormData.safCredit || retireFormData.quantity < 1 || retireFormData.quantity > retireFormData.safCredit?.quantity}
                                onClick={retire}
                                color="primary"
                                variant="outlined"
                            >
                                Retire
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <Button
                    variant="outlined"
                    sx={{width: '10%'}}
                    onClick={openLockToMarketDialog}
                >
                    Lock To Market
                </Button>
                {lockToMarketDialogActive &&
                    <Dialog
                        open={lockToMarketDialogActive}
                        onClose={closeLockToMarketDialog}
                        fullWidth
                        maxWidth="sm">
                        <DialogContent>
                            <Typography variant='h2'>
                                Lock to Market
                            </Typography>
                            <FormControl fullWidth sx={{'& > *:not(:last-child)': {marginBottom: '16px'}}}>
                                <Autocomplete
                                    id="lock-to-market-form-credit-select"
                                    options={safCredits.filter((safCredit) => safCredit.state === SafCreditState.AVAILABLE)}
                                    getOptionLabel={(credit) => `${credit.id}: ${getProductFromSafCredit(credit)?.name} | ${credit.quantity} credits`}
                                    renderInput={(params) => <TextField {...params} label="SAF Credit"/>}
                                    value={lockToMarketFormData.safCredit}
                                    onChange={(e, newValue) => {
                                        setLockToMarketFormData(
                                            {
                                                ...lockToMarketFormData,
                                                safCredit: newValue,
                                                quantity: newValue !== null ? newValue.quantity : 0
                                            }
                                        )
                                    }}
                                />
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={lockToMarketFormData.quantity}
                                    onChange={(e) => {
                                        setLockToMarketFormData({
                                            ...lockToMarketFormData,
                                            quantity: Number(e.target.value)
                                        })
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: lockToMarketFormData.safCredit?.quantity,
                                            min: 0
                                        }
                                    }} // Ensures a minimum value of 0
                                    variant="outlined"
                                />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeLockToMarketDialog} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                disabled={!lockToMarketFormData.safCredit || lockToMarketFormData.quantity < 1 || lockToMarketFormData.quantity > lockToMarketFormData.safCredit.quantity}
                                onClick={() => {
                                    lockToMarket()
                                }}
                                color="primary"
                                variant="outlined"
                            >
                                Lock
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Stack>
            <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 1}}>
                <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                >
                    <Tab
                        label="Available"
                        value={SafCreditState.AVAILABLE}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Locked To Market"
                        value={SafCreditState.LOCKED_TO_MARKET}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Retired"
                        value={SafCreditState.RETIRED}
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                </Tabs>
            </Box>
            <SafCreditTable filterState={tabValue} setTabValue={setTabValue}/>
        </>
    )
}

export default SafManagement